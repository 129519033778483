import { Component } from '@angular/core';

@Component({
  selector: 'app-equal-opportunity-employer',
  templateUrl: './equal-opportunity-employer.component.html',
  styleUrls: ['./equal-opportunity-employer.component.scss']
})
export class EqualOpportunityEmployerComponent {

}
