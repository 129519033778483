<!--header-->
<div id="sidebar1" class="scroll_down hidden-sm">
    <ul>
      <li><a [routerLink]="['/discover-life-at-itc-infotech']" class="page-scroll">Discover Life at ITC Infotech</a></li> 
      <li><a [routerLink]="['/why-itc-infotech']"class="page-scroll">Why ITC Infotech</a></li>
      <li><a [routerLink]="['/jobslist']" class="page-scroll">Open Positions</a></li>
      <li><a [routerLink]="['/equal-opportunity-employer']" class="page-scroll">Equal Opportunity Employer</a></li>
    </ul>
</div>
<nav class="navbar navbar-expand-lg hidden-lg">
    <div class="container-fluid">
        <div></div>
      <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#mobile-menu">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="mobile-menu">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/discover-life-at-itc-infotech']"> Discover Life at ITC Infotech </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/why-itc-infotech']"> Why ITC Infotech </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/jobslist']"> Open Positions </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/equal-opportunity-employer']"> Equal Opportunity Employer </a>
          </li>
        </ul>
      </div>
    </div>
</nav>
<!--header-->
<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <a [routerLink]="['/discover-life-at-itc-infotech']" aria-label="Discover Life">
        <div class="slide-1 d-block w-100">
        </div>
      </a>
    </div>
    <div class="carousel-item">
      <a [routerLink]="['/why-itc-infotech']" aria-label="Why ITC">
        <div class="slide-2 d-block w-100">
        </div>
      </a>
    </div>
    <div class="carousel-item">
      <a [routerLink]="['/equal-opportunity-employer']" aria-label="Equal Opportunity">
        <div class="slide-3 d-block w-100">
        </div>
      </a>
    </div>
    <div class="carousel-item">
      <a href="https://itcinfotech.edcast.com/user/login" aria-label="Edulearn">
        <div class="slide-4 d-block w-100">
        </div>
      </a>
    </div>
  </div>
</div>
<section class="itc-filter">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-sm-12 col-xs-12">
        <div class="header-banner-box">
          <div class="spot-div">
            <a target="_blank" [routerLink]="['/jobslist']">Search by Skill</a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12">
        <div class="header-banner-box">
          <div class="spot-div">
            <a target="_blank" [routerLink]="['/jobslist']">Search by Location</a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12">
        <div class="header-banner-box">
          <div class="spot-div">
            <a target="_blank" [routerLink]="['/jobslist']">View all Openings</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="open-position">
  <div class="container text-center" *ngIf="!!fieldValue;else allJobsDisplayTemplateRef">
    <div class="row">
      <lib-jobs-list-es (jobURL)="setJobURL($event)" [fieldValue]="fieldValue" class="deparment-list"></lib-jobs-list-es>
    </div>
  </div>
  <ng-template #allJobsDisplayTemplateRef>
    <div class="container">
      <div class="row">
        <div class="col-md-12 brdr-line">
          <div class="col-md-12 dsply-nn">
            <lib-zm-search appearance="outline" label="Search" placeholder="Enter keywords to search" [showSearchIconInside]="false" (onSearchEvent)="refreshJobsOnSearch($event)"></lib-zm-search>
          </div>
          <div class="col-md-12">
            <h2 class="job-disc-head">Open Positions</h2>
          </div>
          <div class="col-md-12">
            <lib-jobs-list [showMoreBtn]="false" (jobURL)="setJobURL($event)" class="job-list-sec homepagejoblist"></lib-jobs-list>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-8 d-flex justify-content-end">
                <lib-pagination></lib-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<section class="job-disc">
  <div class="container">
    <div class="col-md-12">
      <h2 class="job-disc-head">Job Disclaimer - ITC Infotech</h2>
      <p class="p-new">It is hereby cautioned to the Candidates that, recently we noticed that some individuals and recruitment agencies have represented themselves to be related to the company by sending fake e-mails to potential job seekers and lured them with job offers in the name of ITC Infotech. These individuals/ recruitment agencies are using the name of ITC Infotech to solicit job applications and in return requiring the job seekers / applicants to pay processing fees or deposit amounts by sending phishing e-mails or by making fraudulent telephone calls.</p>
      <p class="p-new">On receipt of an email relating to an offer or an interview call for any job in ITC Infotech, the candidate should take reasonable measures such as obtaining the contact details from human resources page in this website and notifying us of such fraudulent acts to help us curb such activities. For further information you may reach us at <a href="mailto:recruitment.managers@itcinfotech.com" class="mn-clr">recruitment.managers@itcinfotech.com</a></p>
    </div>
  </div>
</section>