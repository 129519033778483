import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DiscoverLifeAtItcInfotechComponent } from './discover-life-at-itc-infotech/discover-life-at-itc-infotech.component';
import { WhyItcInfotechComponent } from './why-itc-infotech/why-itc-infotech.component';
import { EqualOpportunityEmployerComponent } from './equal-opportunity-employer/equal-opportunity-employer.component';
import { JobconfirmComponent } from './jobconfirm/jobconfirm.component';
import { JoblistComponent } from './joblist/joblist.component';
import { JobviewComponent } from './jobview/jobview.component';
import { MainComponent } from './main/main.component';

const routes: Routes = [
  { path: '',  pathMatch: 'full', component: HomeComponent },
  { path: 'jobslist', component: JoblistComponent },
  { path: 'jobslist/:fieldName/:fieldValue', component: JoblistComponent },
  { path: 'jobview/', component: JobviewComponent },
  { path: 'jobview/:jobUrl', component: JobviewComponent },
  { path: 'jobview/campus/:jobUrl', component: JobviewComponent },
  { path: 'discover-life-at-itc-infotech', component: DiscoverLifeAtItcInfotechComponent },
  { path: 'why-itc-infotech', component: WhyItcInfotechComponent },
  { path: 'equal-opportunity-employer', component: EqualOpportunityEmployerComponent },
  { path: 'confirm', component: JobconfirmComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
