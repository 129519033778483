<div class="header fixed-top">
    <div class="headerSubDiv">
      <div class="custom-Defthm-logoImg">
        <a href="https://www.itcinfotech.com/" target="_blank">
          <img src="assets/itc-logo.png" alt="Logo" width="110" height="60">
        </a>
      </div>
      <div class="home-btn">
        <a href=".">Home</a>
      </div>
    </div>
</div>