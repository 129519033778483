import { Component } from '@angular/core';

@Component({
  selector: 'app-discover-life-at-itc-infotech',
  templateUrl: './discover-life-at-itc-infotech.component.html',
  styleUrls: ['./discover-life-at-itc-infotech.component.scss']
})
export class DiscoverLifeAtItcInfotechComponent {

}
