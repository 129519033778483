import { Component } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-jobconfirm',
  templateUrl: './jobconfirm.component.html',
  styleUrls: ['./jobconfirm.component.scss']
})
export class JobconfirmComponent {
  message: string = '';
  finalmessage : any = '';

  constructor(private appService: AppService) { }
  
  ngOnInit(): void {
    if(this.appService.applyConfirmationText != null && this.appService.applyConfirmationText != ""){
      this.finalmessage = this.appService.applyConfirmationText;
    }else{
      this.finalmessage = "Your profile got submited sucessfully";
    }
    this.message = this.finalmessage;
  }

}
