<!--header-->
<div id="sidebar1" class="scroll_down hidden-sm">
  <ul>
    <li class="nav-active"><a [routerLink]="['/discover-life-at-itc-infotech']" class="page-scroll">Discover Life at ITC Infotech</a></li> 
    <li><a [routerLink]="['/why-itc-infotech']"class="page-scroll">Why ITC Infotech</a></li>
    <li><a [routerLink]="['/jobslist']" class="page-scroll">Open Positions</a></li>
    <li><a [routerLink]="['/equal-opportunity-employer']" class="page-scroll">Equal Opportunity Employer</a></li>
  </ul>
</div>
<nav class="navbar navbar-expand-lg hidden-lg">
  <div class="container-fluid">
      <div></div>
    <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#mobile-menu">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="mobile-menu">
      <ul class="navbar-nav">
        <li class="nav-item nav-active">
          <a class="nav-link" [routerLink]="['/discover-life-at-itc-infotech']"> Discover Life at ITC Infotech </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/why-itc-infotech']"> Why ITC Infotech </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/jobslist']"> Open Positions </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/equal-opportunity-employer']"> Equal Opportunity Employer </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!--header-->
<section class="life-at-itc">
    <div class="container">
        <div class="row">
            <h2>Discover Life at ITC Infotech</h2>
            <p class="p-new">ITC Infotech focuses on employee experience and value professionals who are passionate about providing solutions by applying their core strengths and are invested in leveraging opportunities to charter own careers.</p>
            <p class="p-new">The five elements that we measure the Employee Value Proposition (EVP) at ITC Infotech are respect, trust, and inclusion, growth, wellbeing, and rewards and recognition. The behavioral attributes are anchored through our values known as 'CREATE'</p>
            <img src="assets/itc-values-create.webp" width="100%" height="100%" alt="Life at ITC" loading="lazy">
        </div>
    </div>
</section>
<section class="testimonial">
    <div class="container">
        <div class="row">
            <h2>Leadership Testimonials</h2>
            <p class="p-new">Leadership provides the company with direction of growth; the right leadership nurtures teams to learn, share and grow together. Our Leadership embrace diversity of thought and have built teams that have created a winning streak. Here is what our leaders have to say</p>
            <div id="testimonialCarousel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#testimonialCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#testimonialCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#testimonialCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active"> 
                    <div class="col-md-12">
                        <div class="testimonial-contain">
                            <img class="testimonial-img" src="assets/Jaismon_Emmanuel.webp" alt="Testimonials" loading="lazy" width="200" height="200">
                            <h5 class="testimonial-head">Jaismon Emmanuel, Head - Business Excellence</h5>
                            <p class="testimonial-para p-new">“ITC Infotech’s industry-defining Work From Anywhere (I-WFA) framework helps attract and build a pool of best-in-class talent in our organization. With our strategic focus on Employee Centricity, we provide the best workplace experience for our people and prepare them to be future-ready.”</p>
                        </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="col-md-12">
                        <div class="testimonial-contain">
                            <img src="assets/Vishal_Kumar.webp" alt="Testimonials" loading="lazy" width="200" height="200">
                            <h5 class="testimonial-head">Vishal Kumar, President & Regional Head (APAC, MEA and India)</h5>
                            <p class="testimonial-para p-new">“ITC Infotech is one of the leading names considered for business solutions, globally. With our expanding global footprint, the time is apt for career aspirants to take on challenging roles and work with brands around the world.”</p>
                        </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="col-md-12">
                        <div class="testimonial-contain">
                            <img src="assets/MalligaRajkumar.webp" alt="Testimonials" loading="lazy" width="200" height="200">
                            <h5 class="testimonial-head">Malliga Rajkumar, CHRO</h5>
                            <p class="testimonial-para p-new">“At ITC Infotech, we are guided by our core values, “CREATE” which defines who we are and what we stand for. We continue to invest in people-centric initiatives to provide greater flexibility and learning opportunities to our employees. We consistently evaluate and nurture talent to create a high-performance culture.”</p>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="emp-testimonial">
    <div class="container">
        <div class="row">
            <h2>Employee Testimonials</h2>
            <div id="empTestimonialCarousel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#empTestimonialCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#empTestimonialCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#empTestimonialCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active"> 
                    <div class="col-md-12">
                        <div class="emp-testimonial-contain">
                            <iframe class="video_mb" src="https://www.youtube.com/embed/KKSpb5jHn-c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            <p class="p-new">Our Initiative to ensure a smooth and seamless onboarding of new joiners to the ITC Infotech family.</p>
                        </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="col-md-12">
                        <div class="emp-testimonial-contain">
                            <iframe class="video_mb" src="https://www.youtube.com/embed/K2tqB8oY9RE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            <p class="p-new">Our employees enjoy the benefit of knowledge through our next gen learning experience platform, here is what they have to say about it.</p>
                        </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="col-md-12">
                        <div class="emp-testimonial-contain">
                            <iframe class="video_mb" src="https://www.youtube.com/embed/hWOvU8-Rz_I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            <p class="p-new">A personalized digital assistant to help find answers to any query our employees have through their life cycle with the organization. With Digital buddy, answers are at your fingertips.</p>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="itc-map">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>Operating in 39 Countries with 40 Nationalities</h2>
                <img src="assets/global-p.webp" alt="ITC Map" loading="lazy" width="100%" height="auto">
            </div>
        </div>
    </div>
</section>