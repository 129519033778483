<!--header-->
<div id="sidebar1" class="scroll_down hidden-sm">
  <ul>
    <li><a [routerLink]="['/discover-life-at-itc-infotech']" class="page-scroll">Discover Life at ITC Infotech</a></li> 
    <li class="nav-active"><a [routerLink]="['/why-itc-infotech']"class="page-scroll nav-active">Why ITC Infotech</a></li>
    <li><a [routerLink]="['/jobslist']" class="page-scroll">Open Positions</a></li>
    <li><a [routerLink]="['/equal-opportunity-employer']" class="page-scroll">Equal Opportunity Employer</a></li>
  </ul>
</div>
<nav class="navbar navbar-expand-lg hidden-lg">
  <div class="container-fluid">
      <div></div>
    <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#mobile-menu">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="mobile-menu">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/discover-life-at-itc-infotech']"> Discover Life at ITC Infotech </a>
        </li>
        <li class="nav-item nav-active">
          <a class="nav-link nav-active" [routerLink]="['/why-itc-infotech']"> Why ITC Infotech </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/jobslist']"> Open Positions </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/equal-opportunity-employer']"> Equal Opportunity Employer </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!--header-->
<section class="why-itc">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>Why ITC Infotech</h2>
                <h5>We touch millions of lives through technology</h5>
                <p class="p-new">ITC Infotech is a leading global technology services and solutions provider, led by Business and Technology Consulting. ITC Infotech provides business-friendly solutions to help clients succeed and be future-ready, by seamlessly bringing together digital expertise, strong industry speciﬁc alliances and the unique ability to leverage deep domain expertise from ITC Group businesses. The company provides technology solutions and services to enterprises across industries such as Banking & Financial Services, Healthcare, Manufacturing, Consumer Goods, Travel and Hospitality, through a combination of traditional and newer business models, as a long-term sustainable partner.</p>
                <p class="p-new">ITC Infotech is a wholly owned subsidiary of ITC Ltd. ITC is one of India’s leading private sector companies and a diversified conglomerate with businesses spanning Consumer Goods, Hotels, Paperboards and Packaging, Agri Business, and Information Technology.</p>
                <p class="p-new">For more information, please visit: <a href="http://www.itcinfotech.com/" class="mn-clr">http://www.itcinfotech.com/</a></p>
            </div>
        </div>
    </div>
</section>
<section class="itc-community">
    <div class="container">
        <div class="row">
            <h2>Our community</h2>
            <div class="col-md-3">
                <h4 class="num-1">60+</h4>
                <p class="num-2">Fortune Listed Clients</p>
            </div>
            <div class="col-md-3">
                <h4 class="num-1">40</h4>
                <p class="num-2">Countries</p>
            </div>
            <div class="col-md-3">
                <h4 class="num-1">6</h4>
                <p class="num-2">Industry Verticals</p>
            </div>
            <div class="col-md-3">
                <h4 class="num-1">10,700+</h4>
                <p class="num-2">Employees</p>
            </div>
        </div>
    </div>
</section>
<section class="itc-domaim">
    <div class="container">
        <div class="row">
            <h2>Our Deep Domain Expertise</h2>
            <div id="itcDomainCarousel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#itcDomainCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#itcDomainCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#itcDomainCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active"> 
                    <div class="row dsply-flx-1">
                        <div class="col-md-8">
                            <img src="assets/P-4.gif" width="100%" height="100%" alt="ITC Domain" loading="lazy">  
                        </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="row dsply-flx-1">
                        <div class="col-md-8">  
                            <img src="assets/CIO-360-v6.gif" width="100%" height="100%" alt="ITC Domain" loading="lazy">
                        </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="row dsply-flx-1">
                        <div class="col-md-8">
                            <img src="assets/digital-exp.gif" width="100%" height="100%" alt="ITC Domain" loading="lazy">
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</section>