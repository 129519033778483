<!--header-->
<div id="sidebar1" class="scroll_down hidden-sm">
  <ul>
    <li><a [routerLink]="['/discover-life-at-itc-infotech']" class="page-scroll">Discover Life at ITC Infotech</a></li> 
    <li><a [routerLink]="['/why-itc-infotech']"class="page-scroll">Why ITC Infotech</a></li>
    <li class="nav-active"><a [routerLink]="['/jobslist']" class="page-scroll nav-active">Open Positions</a></li>
    <li><a [routerLink]="['/equal-opportunity-employer']" class="page-scroll">Equal Opportunity Employer</a></li>
  </ul>
</div>
<nav class="navbar navbar-expand-lg hidden-lg">
  <div class="container-fluid">
      <div></div>
    <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#mobile-menu">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="mobile-menu">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/discover-life-at-itc-infotech']"> Discover Life at ITC Infotech </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/why-itc-infotech']"> Why ITC Infotech </a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-active" [routerLink]="['/jobslist']"> Open Positions </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/equal-opportunity-employer']"> Equal Opportunity Employer </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!--header-->
<section class="mtb-30 mt-80 joblist-sec pd-btm-50">
  <div class="container">
    <div class="row">
      <div class="cntr-div">
        <h2 class="abt-cntr">Open Positions</h2>
      </div>
    </div>
  </div>
  <!-- <lib-find-your-spot></lib-find-your-spot> -->
  <div class="container text-center" *ngIf="!!fieldValue;else allJobsDisplayTemplateRef">
    <div class="row">
      <lib-jobs-list-es (jobURL)="setJobURL($event)" [fieldValue]="fieldValue" class="deparment-list"></lib-jobs-list-es>
    </div>
  </div>
  <ng-template #allJobsDisplayTemplateRef>
    <div class="container kohler-joblist-space">
      <div class="row">
        <!--<div class="col-md-3 mt-2"><lib-facets class="width-auto facet-lib"></lib-facets></div>-->
        <div *ngIf="!isMobileView" class="col-lg-3">
          <lib-facets [isPopupRequired]=true [isHorizontal]=false themeName="zm-custom-theme" class="d-none d-lg-block"></lib-facets>
        </div>
        <div class="col-md-9">
          <div class="col-12">
            <lib-zm-search appearance="outline" label="Search" placeholder="Enter keywords to search" [showSearchIconInside]="true" (allJobsCount)="setJobsCount($event)" (onSearchEvent)="refreshJobsOnSearch($event)"></lib-zm-search>
            <span class="filter-btn">
              <button class="m-3" *ngIf="isMobileView" (click)="openPopup()">Filters</button>
            </span>
            <lib-filter-chips class="d-none-mob"></lib-filter-chips>
          </div>
          <div class="col-12 mb-4">
            <div class="count-banner" *ngIf="allJobsCount >= 0">
              {{allJobsCount}} openings found
            </div>
            <lib-jobs-list (emitApplyData)="setTalentApplyResult($event)" [showMoreBtn]="true" [chipsViewForSkills]="true" (jobURL)="setJobURL($event)" class="job-list-sec"></lib-jobs-list>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-8 d-flex justify-content-end">
                <lib-pagination></lib-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</section>
<ng-template #filtersPopup>
  <div class="d-flex justify-content-between mb-3 mt-2 mx-1">
    <div>Filters</div>
    <button class="btn-popup-close" (click)="closePopup()"><img src="assets/close-sm.svg" alt="close" height="24" width="24"></button>
  </div>
  <lib-filter-chips></lib-filter-chips>
  <lib-facets [isPopupRequired]=true [isUsedInPopup]=true [isHorizontal]=false [isClearAll]="clearAll" themeName="zm-custom-theme"></lib-facets>
  <div class="d-lg-none d-flex justify-content-end footer">
    <button class="clear-sec" (click)="clearSideFilter()">CLEAR ALL</button>
    <button mat-stroked-button class="ms-3 me-2 close-sec" (click)="closePopup()">SHOW JOBS</button>  </div>
</ng-template>