import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { environment as env } from '../../environments/environment';
import { RecaptchaComponent } from 'ng-recaptcha';
declare var window: any;

@Component({
  selector: 'app-jobview',
  templateUrl: './jobview.component.html',
  styleUrls: ['./jobview.component.scss']
})
export class JobviewComponent implements OnInit {

  jobUrl: string = "";
  loadApplyForm: boolean = false;
  isApplyFormLoaded: boolean = false;
  loaderLoad: boolean = false;
  jobTitle: string = '';
  loaderDataLoad: boolean = true;
  jobDetails:any = '';
  jobDetailsAttr: string [] = [];
  location: string = '';
  jobSuggestions: any = [];
  createdDate: string = '';
  postedDate: string = '';
  jobDepartement:any = '';
  @ViewChild('target') target: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private appService: AppService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      this.jobUrl = params.jobUrl;
    });
    RecaptchaComponent.prototype.ngOnDestroy = function() {
    }
  }

  onBackClick(): void {
    this.router.navigate(['']);
  }

  onApplyClick(): void {
    this.loadApplyForm = true;
    this.loaderLoad = true;
  }

  scrollToApply(isApplyFormLoaded: any): void {
    this.isApplyFormLoaded = isApplyFormLoaded;
    setTimeout(()=>{
      this.target.nativeElement.scrollIntoView({ behavior: "smooth" });
      this.loaderLoad = false;
      const label = document.getElementById('sentenceForTnC');
      const spanEle = document.createElement('span');
      if(this.jobDepartement == 'itcinfotechindialtd.I2AUS-ITC INFOTECH AUSTRALIA') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-1" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Australia Employment Candidates.</a>';
      } 
      else if(this.jobDepartement == 'itcinfotechindialtd.I2AUS-ITC INFOTECH AUSTRALIA.Lateral') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-1" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Australia Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2AUS-ITC INFOTECH AUSTRALIA.3PT') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-1" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Australia Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2AUS-ITC INFOTECH AUSTRALIA.Campus') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-1" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Australia Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2AUS-ITC INFOTECH AUSTRALIA.FTE') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-1" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Australia Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2RSA-ITC INFOTECH SOUTH AFRICA') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-2" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for South Africa Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2RSA-ITC INFOTECH SOUTH AFRICA.Lateral') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-2" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for South Africa Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2RSA-ITC INFOTECH SOUTH AFRICA.3PT') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-2" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for South Africa Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2RSA-ITC INFOTECH SOUTH AFRICA.Campus') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-2" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for South Africa Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2RSA-ITC INFOTECH SOUTH AFRICA.FTE') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-2" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for South Africa Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.ITI2A-ITC INFOTECH AMERICA INC') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-4" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for USA Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.ITI2A-ITC INFOTECH AMERICA INC.Lateral') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-4" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for USA Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.ITI2A-ITC INFOTECH AMERICA INC.3PT') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-4" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for USA Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.ITI2A-ITC INFOTECH AMERICA INC.Campus') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-4" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for USA Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.ITI2A-ITC INFOTECH AMERICA INC.FTE') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-4" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for USA Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2BRA-ITC INFOTECH BRAZIL') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-3" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Brazil Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2BRA-ITC INFOTECH BRAZIL.Lateral') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-3" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Brazil Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2BRA-ITC INFOTECH BRAZIL.FTE') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-3" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Brazil Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2BRA-ITC INFOTECH BRAZIL.Campus') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-3" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Brazil Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2BRA-ITC INFOTECH BRAZIL.3PT') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-3" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Brazil Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2SGP-ITC INFOTECH SINGAPORE') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-5" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Singapore Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2SGP-ITC INFOTECH SINGAPORE.Lateral') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-5" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Singapore Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2SGP-ITC INFOTECH SINGAPORE.3PT') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-5" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Singapore Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2SGP-ITC INFOTECH SINGAPORE.Campus') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-5" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Singapore Employment Candidates.</a>';
      }
      else if(this.jobDepartement == 'itcinfotechindialtd.I2SGP-ITC INFOTECH SINGAPORE.FTE') {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy-5" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Singapore Employment Candidates.</a>';
      }
      else {
        spanEle.innerHTML = '<a>I agree and accept ITC Infotech <a data-bs-toggle="modal" data-bs-target="#readPolicy" data-backdrop="static" data-keyboard="false" style="color:#470b6b;cursor: pointer;font-weight: bold;text-decoration: underline !important;">Privacy Notice for Employment Candidates.</a>';
      }
      label?.appendChild(spanEle);
    },0);
  }

  onJobSubmission(response: any): void {
    this.loaderLoad = true;
    if (response.code == 200) {
      this.loaderLoad = false;
      this.appService.applyConfirmationText = response.message;
      this.router.navigate(['confirm']);
    }
    else {
      this.appService.toast('error', response.message);
      this.loaderLoad = false;
    }
  }
  applyToJob(response: any) {
    this.loaderLoad = true;
    if (response.code == 200) {
      this.loaderLoad = false;
    }
  }
  onJobViewLoad(data: any) {
    let jobData = data.jobData;
    this.jobDetails = jobData;
    this.jobTitle = jobData.jobTitle;
    this.jobDepartement = jobData.department.departmentFullcode;
    this.location = jobData.location;
    this.createdDate = jobData.createdDate;
    let createda = jobData.createdDate;
    let now = new Date().getTime();
    let diff = now - createda;
    var n = (now - createda)/1000;
    if(n < 3600){
      var diffdate = 'just now';
    }else if(n < 86400){
      var hours_n = Math.floor(n/3600);
      var diffdate= hours_n + ' hour' + (hours_n > 1 ? 's' : '') + ' ago';
    }else if (n < 2629743) { // less than a week
      var day_n = Math.floor(n/86400);
      var diffdate= day_n + ' day' + (day_n > 1 ? 's' : '') +' ago';
    }
    else if (n < 31556926) { // less than 12 months
    var month_n = Math.floor(n/2629743);
    var diffdate= month_n + ' month' + (month_n > 1 ? 's' : '') +' ago';
    } else {
    var year_n = Math.floor(n/31556926);
    var diffdate= year_n + ' year' + (year_n > 1 ? 's' : '') +' ago';
    }
    this.postedDate = diffdate;
    if(this.jobDetails != '' && this.jobDetails != null){
      this.loaderDataLoad = false;
    }
    }

    genericSocialShare(platform: string) {
      const currDate = new Date();
      const href = window.location.href;
      const hostname = window.location.hostname;
      const jobShareURL = env.JOBSHAREURL;
      // if(localStorage.getItem('seoJobPreviewType') == 1 || localStorage.getItem('seoJobPreviewType') =='1')	{
      //   JOB_SHARE_URL =window.location.origin;
      // }
      let url = '';
      let triggerURL = '';
      let domainURL = '&apiDomain=' + env.APIENDPOINT.replace('http://', '').replace('https://', '').replace('/', '');
      let twitterParm = '';
    if (href.includes('?')) {
      twitterParm = '&';
    } else {
      twitterParm = '?';
    }
      switch (platform) {
        case 'fb':
          url = jobShareURL + '/job_preview/?jobUrl=' + this.jobUrl + '&externalSource=facebookShare&campusUrl=empty&host=' + hostname + domainURL;
          triggerURL = 'https://facebook.com/sharer.php?u=' + encodeURIComponent(url);
          break;
        case 'linkedin':
          url = jobShareURL + '/job_preview/?jobUrl=' + this.jobUrl + '&externalSource=share-linkedin&campusUrl=empty&host=' + hostname + domainURL;
          triggerURL = 'https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(url);
          break;
        case 'twitter':
          url = href + twitterParm +'source=share-twitter' + '&v=' + currDate.getTime();
          triggerURL = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(this.jobUrl + ' ' + url);
          break;
        default:
          break;
      }
      platform == 'twitter' ? window.open(triggerURL, this.jobUrl, 'width=640,height=480') : window.open(triggerURL);
    }
    setJobURL(jobURL: any): void {
      let url = window.location.href;
      url = url.substring(0, url.indexOf('jobslist/')) + 'jobview/' + jobURL;
      // let url = this.router.serializeUrl(this.router.createUrlTree(['jobview/' + jobURL]));
      window.open(url, '_blank');
    }
    getJobSuggestions(event: any) {
      this.jobSuggestions = event;
    }
}
