<footer id="footer" class="classic underline-effect">
    <div class="upper-footer">
      <div class="container cstm-1-wdth">
        <div class="footer-bar ">
          <div class="footer-nav-menu">
            <ul id="menu-footer-menu" class="navbar-footer">
              <li id="menu-item-24511" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-24511">
                <a href="https://www.itcinfotech.com/about-us/">About Us</a>
              </li>
              <li id="menu-item-24512" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-24512">
                <a href="https://www.itcinfotech.com/awards-and-recognition/">Awards</a>
              </li>
              <li id="menu-item-30954" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-30954">
                <a href="https://www.itcinfotech.com/covid19-update/">COVID-19</a>
              </li>
              <li id="menu-item-24513" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-24513">
                <a href="https://www.itcinfotech.com/employee-centricity/">Employees</a>
              </li>
              <li id="menu-item-24514" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-24514">
                <a href="https://www.itcinfotech.com/events/">Events</a>
              </li>
              <li id="menu-item-24515" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-24515">
                <a href="https://www.itcinfotech.com/locations/">Locations</a>
              </li>
              <li id="menu-item-25651" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-25651">
                <a href="https://www.itcinfotech.com/news/">News</a>
              </li>
            </ul>
          </div>
          <div class="footer-socials-bar">
            <ul class="redux-social-media-list clearfix">
              <li style="list-style: none;">
                <a href="https://www.itcinfotech.com/twitter">
                  <i class="fa fa-twitter" title="" aria-hidden="true"></i>
                </a>
              </li>
              <li style="list-style: none;">
                <a href="https://www.itcinfotech.com/instagram">
                  <i class="fa fa-instagram" title="" aria-hidden="true"></i>
                </a>
              </li>
              <li style="list-style: none;">
                <a href="https://www.itcinfotech.com/facebook-f">
                  <i class="fa fa-facebook-f" title="" aria-hidden="true"></i>
                </a>
              </li>
              <li style="list-style: none;">
                <a href="https://www.itcinfotech.com/linkedin">
                  <i class="fa fa-linkedin" title="" aria-hidden="true"></i>
                </a>
              </li>
              <li style="list-style: none;">
                <a href="https://www.itcinfotech.com/youtube">
                  <i class="fa fa-youtube" title="" aria-hidden="true"></i>
                </a>
              </li>
              <li style="list-style: none;">
                <a href="https://www.itcinfotech.com/telegram">
                  <i class="fa fa-telegram" title="" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer-widget-area">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 first-widget-area">
            <div id="nav_menu-7" class="footer_widget widget widget_nav_menu">
              <h5 class="widget-title">
                <span>Company</span>
              </h5>
              <div class="menu-about-us-container">
                <ul id="menu-about-us" class="menu">
                  <li id="menu-item-36578" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36578">
                    <a href="https://www.itcinfotech.com/about-us/">About Us</a>
                  </li>
                  <li id="menu-item-36579" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36579">
                    <a href="https://www.itcinfotech.com/awards-and-recognition/">Awards</a>
                  </li>
                  <li id="menu-item-36580" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36580">
                    <a href="https://www.itcinfotech.com/covid19-update/">Covid 19</a>
                  </li>
                  <li id="menu-item-36582" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36582">
                    <a href="https://www.itcinfotech.com/employee-centricity/">Employee Centricity</a>
                  </li>
                  <li id="menu-item-36581" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36581">
                    <a href="https://www.itcinfotech.com/customer-centricity/">Customer Centricity</a>
                  </li>
                  <li id="menu-item-36583" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36583">
                    <a href="https://www.itcinfotech.com/operational-and-delivery-excellence/">Operational Excellence</a>
                  </li>
                  <li id="menu-item-36584" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36584">
                    <a href="https://www.itcinfotech.com/partners/">Partners</a>
                  </li>
                  <li id="menu-item-36585" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36585">
                    <a href="https://www.itcinfotech.com/locations/">Locations</a>
                  </li>
                  <li id="menu-item-37129" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-37129">
                    <a href="https://jobs.itcinfotech.com/#!">Careers</a>
                  </li>
                  <li id="menu-item-37178" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-37178">
                    <a href="https://www.itcinfotech.com/social-media-feed/">Social Feed</a>
                  </li>
                  <li id="menu-item-38040" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-38040">
                    <a href="https://www.itcinfotech.com/i-wfa/">I – WFA</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 second-widget-area">
            <div id="nav_menu-10" class="footer_widget widget widget_nav_menu">
              <h5 class="widget-title">
                <span>Services</span>
              </h5>
              <div class="menu-services-container">
                <ul id="menu-services" class="menu">
                  <li id="menu-item-36594" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36594">
                    <a href="https://www.itcinfotech.com/cio-360/">CIO 360</a>
                  </li>
                  <li id="menu-item-36592" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36592">
                    <a href="https://www.itcinfotech.com/digital-experience/">Digital Experience</a>
                  </li>
                  <li id="menu-item-38963" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-38963">
                    <a href="https://www.itcinfotech.com/dxp-services/">DxP Services</a>
                  </li>
                  <li id="menu-item-36593" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36593">
                    <a href="https://www.itcinfotech.com/product-4-0/">Product 4.0</a>
                  </li>
                </ul>
              </div>
            </div>
            <div id="nav_menu-8" class="footer_widget widget widget_nav_menu">
              <h5 class="widget-title">
                <span>Industries</span>
              </h5>
              <div class="menu-industries-container">
                <ul id="menu-industries" class="menu">
                  <li id="menu-item-36595" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36595">
                    <a href="https://www.itcinfotech.com/industries/banking-and-financial-services/">Banking &amp; Financial Services</a>
                  </li>
                  <li id="menu-item-36596" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36596">
                    <a href="https://www.itcinfotech.com/industries/consumer-packaged-goods/">Consumer Packaged Goods</a>
                  </li>
                  <li id="menu-item-36597" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36597">
                    <a href="https://www.itcinfotech.com/industries/healthcare/">Healthcare &amp; Life Sciences</a>
                  </li>
                  <li id="menu-item-36598" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36598">
                    <a href="https://www.itcinfotech.com/industries/hospitality/">Hospitality</a>
                  </li>
                  <li id="menu-item-36599" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36599">
                    <a href="https://www.itcinfotech.com/industries/manufacturing/">Manufacturing</a>
                  </li>
                  <li id="menu-item-36600" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36600">
                    <a href="https://www.itcinfotech.com/industries/travel/">Travel</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 third-widget-area">
            <div id="nav_menu-9" class="footer_widget widget widget_nav_menu">
              <h5 class="widget-title">
                <span>Resources</span>
              </h5>
              <div class="menu-resources-container">
                <ul id="menu-resources" class="menu">
                  <li id="menu-item-36586" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36586">
                    <a href="https://www.itcinfotech.com/blogs/">Blogs</a>
                  </li>
                  <li id="menu-item-36591" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36591">
                    <a href="https://www.itcinfotech.com/news/">News</a>
                  </li>
                  <li id="menu-item-36587" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-36587">
                    <a href="https://www.itcinfotech.com/resources/?wpv-post_tag=case-study&amp;wpv_aux_current_post_id=29264&amp;wpv_view_count=29891">Case Studies</a>
                  </li>
                  <li id="menu-item-36588" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-36588">
                    <a href="https://www.itcinfotech.com/resources/?wpv-post_tag=whitepaper&amp;wpv_aux_current_post_id=29264&amp;wpv_view_count=29891">Whitepapers</a>
                  </li>
                  <li id="menu-item-36589" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-36589">
                    <a href="https://www.itcinfotech.com/resources/?wpv-post_tag=brochure&amp;wpv_aux_current_post_id=29264&amp;wpv_view_count=29891">Brochures</a>
                  </li>
                  <li id="menu-item-36590" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36590">
                    <a href="https://www.itcinfotech.com/events/">Events</a>
                  </li>
                  <li id="menu-item-36948" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-36948">
                    <a href="https://www.itcinfotech.com/videos/">Videos</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 forth-widget-area">
            <div id="text-3" class="footer_widget widget widget_text" style="
                  font-family: 'Karla' !important;
              ">
              <h5 class="widget-title">
                <span>Connect With Us</span>
              </h5>
              <div class="textwidget">
                <p> Corporate Indentity Number: U65991WB1996PLC077341 <br> For any queries or grievances, contact recruitment.managers@itcinfotech.com <br>
                  <br> Registered Address: Virginia House, 37 J.L. Nehru Road, <br> Kolkata - 700071, West Bengal, India <br>
                  <br> Corporate Address: No.18, Gate, 1 & 2, Dodda Banaswadi Main Rd, Maruthiseva Nagara, <br> Bengaluru - 560005
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lower-footer">
      <div class="container cstm-1-wdth">
        <span>
          <div class="row ">
            <div class="col-md-6 col-sm-12 footer-social-icons">
              <a target="_blank" href="https://twitter.com/itcinfotech">
                <img src="assets/twitter-svg.svg" alt="twitter" height="10" width="10" loading="lazy" class="ftr-scl-icn">
              </a>
              <a target="_blank" href="https://www.instagram.com/itcinfotech/">
                <img src="assets/instagram-svg.svg" alt="instagram" height="10" width="10" loading="lazy" class="ftr-scl-icn">
              </a>
              <a target="_blank" href="https://www.facebook.com/itcinfotech/">
                <img src="assets/facebook-f-svg.svg" alt="facebook" height="10" width="10" loading="lazy" class="ftr-scl-icn">
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/itc-infotech">
                <img src="assets/linkedin-in-svg.svg" alt="linkedin" height="10" width="10" loading="lazy" class="ftr-scl-icn">
              </a>
              <a target="_blank" href="https://www.youtube.com/user/ITCInfotech1">
                <img src="assets/youtube-svg.svg" alt="youtube" height="10" width="10" loading="lazy" class="ftr-scl-icn">
              </a>
              <a target="_blank" href="https://t.me/itcinfotech1">
                <img src="assets/telegram-svg.svg" alt="telegram" height="10" width="10" loading="lazy" class="ftr-scl-icn">
              </a>
            </div>
            <div class="col-md-6 col-sm-12 copyright-section">
              <p class="MsoNormal" style="
                  font-family: 'Karla' !important;
              ">© 2022, <a href="https://www.itcinfotech.com/">ITC Infotech India Ltd</a>. All rights reserved. | Powered by <a target="_blank" href="https://zwayam.com">
                  <img src="assets/zwa.png" height="29" width="87" loading="lazy" alt="zwayam-logo">
                </a>
              </p>
              <p class="MsoNormal footer_menu">
                <a href="https://www.itcinfotech.com/terms-and-conditions/">Terms &amp; Conditions</a> | <a href="https://www.itcinfotech.com/privacy-policy/">Privacy Policy</a> | <a href="https://www.itcinfotech.com/compliance/">Compliance</a> | <a href="https://www.itcinfotech.com/cookie-policy/">Cookie Policy</a>
              </p>
            </div>
          </div>
        </span>
      </div>
    </div>
</footer>