import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { JoblistComponent } from './joblist/joblist.component';
import { ZmCareerSitesLibModule } from 'zm-careers-lib';
import { JobviewComponent } from './jobview/jobview.component';
import { JobconfirmComponent } from './jobconfirm/jobconfirm.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { LoadingDirective } from './directives/loading.directive';
import { HomeComponent } from './home/home.component';
import { DiscoverLifeAtItcInfotechComponent } from './discover-life-at-itc-infotech/discover-life-at-itc-infotech.component';
import { WhyItcInfotechComponent } from './why-itc-infotech/why-itc-infotech.component';
import { EqualOpportunityEmployerComponent } from './equal-opportunity-employer/equal-opportunity-employer.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    JoblistComponent,
    JobviewComponent,
    JobconfirmComponent,
    HeaderComponent,
    FooterComponent,
    LoadingDirective,
    HomeComponent,
    DiscoverLifeAtItcInfotechComponent,
    WhyItcInfotechComponent,
    EqualOpportunityEmployerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ZmCareerSitesLibModule,
    BrowserAnimationsModule,
    MatCardModule
  ],
  exports: [
    LoadingDirective
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
