import { Component, OnInit } from '@angular/core';
import { environment as env } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    sessionStorage.setItem("COMPANYID", env.COMPANYID);
    sessionStorage.setItem("COMPANYURL", env.COMPANYURL);
    sessionStorage.setItem("DOMAIN", env.DOMAIN);
    sessionStorage.setItem("APIENDPOINT", env.APIENDPOINT);
    sessionStorage.setItem("APIENDPOINTNEW", env.APIENDPOINTNEW);
    this.redirectJobURL();
  }
  title = 'itcinfotech';
  onActivate(event: any) {
    // window.scroll(0,0);
 
    window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
     });
    }
    private redirectJobURL() {
      let hash = window.location.hash;
      if (hash.length > 0) {
        let location = window.location.href;
        location = location.replace('#!job-view','jobview');
        location = location.replace('#!/job-view','jobview');
        location.replace('#!/job-view','jobview');
        window.open(location, "_self");
      }
    }
}
