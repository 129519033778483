<!--header-->
<div id="sidebar1" class="scroll_down hidden-sm">
  <ul>
    <li><a [routerLink]="['/discover-life-at-itc-infotech']" class="page-scroll">Discover Life at ITC Infotech</a></li> 
    <li><a [routerLink]="['/why-itc-infotech']"class="page-scroll">Why ITC Infotech</a></li>
    <li><a [routerLink]="['/jobslist']" class="page-scroll">Open Positions</a></li>
    <li class="nav-active"><a [routerLink]="['/equal-opportunity-employer']" class="page-scroll">Equal Opportunity Employer</a></li>
  </ul>
</div>
<nav class="navbar navbar-expand-lg hidden-lg">
  <div class="container-fluid">
      <div></div>
    <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#mobile-menu">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="mobile-menu">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/discover-life-at-itc-infotech']"> Discover Life at ITC Infotech </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/why-itc-infotech']"> Why ITC Infotech </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/jobslist']"> Open Positions </a>
        </li>
        <li class="nav-item nav-active">
          <a class="nav-link" [routerLink]="['/equal-opportunity-employer']"> Equal Opportunity Employer </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!--header-->
<section class="equal-op">
    <img src="assets/equal-opp-banner.webp" width="100%" height="100%" alt="Eqaul Opportunity" loading="lazy">
</section>
<section class="equal-op-cont">
    <div class="container">
        <div class="row">
            <h2>Equal Opportunity Employer</h2>
            <h5>Our People-centric Policies</h5>
            <p class="p-new">ITC Infotech is an equal opportunity employer. We celebrate diversity and are committed to creating an inclusive environment for all employees.</p>
            <h5>Communication</h5>
            <p class="p-new">Open communication is the cornerstone of our people policies. We practice an open-door policy, and have instituted various leadership connect programs, not just for quick grievance redressal, but more to involve employees in shaping the organization’s future.</p>
        </div>
    </div>
</section>