import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  fieldValue: any = "";
  jobRoute: string = '';
  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      console.log(params);
      this.fieldValue = params.fieldValue;
    });
  }

  refreshJobsOnSearch(e: any) {
    console.log("Search button hit");

  }

  showRecommendedJobs: boolean = false;
  onRecommendeJobsFetch(e: any) {
    this.showRecommendedJobs = true;
    console.log("recommended jobs from app", e);

  }

  resetRecommendedJobsEventHandler(e: any) {
    this.showRecommendedJobs = !e;
  }

  setJobURL(jobURL: any): void {
    let url = window.location.href;
    url = url.substring(0, url.indexOf('jobslist/')) + 'jobview/' + jobURL;
    // let url = this.router.serializeUrl(this.router.createUrlTree(['jobview/' + jobURL]));
    window.open(url, '_blank');
  }
}
