<section class="mtb-30 mt-80 jobView-sec">
    <div class="job-details-header">
            <div class="container">
                <div class="row dsply-flx-cntr">
                    <div class="col-md-10">
                           <div class="row">
                            <div class="listing-header jobVwHeading mrgn-tp-btm">
                                <h2 class="defThmSubHeading align-titleJob ">{{jobTitle}}</h2>
                                <div> 
                            <!--<h2 class="theme-posted defThmSubHeading ng-binding">Posted {{postedDate}}</h2>-->
                            <!--<div class="line-left clearfix">|</div>
                            <div class="share-job-box defThmSubHeading customShareJobPadding">
                                                        
                                            <div class="share-job-header align-header-jobShr ng-binding" id="heading1_jobview_jobheading_style2" ng-style="heading1_jobview_jobheading_style">
                                            <i class="fa fa-share-alt" aria-hidden="true"></i> Share Job&nbsp;</div>
                                              
                                              <div style="float: left;">
                                                                  
                                                <div class="social-share" style="float: left;">
                                                    <a href="javascript:void(0)" class="custom-thm8-outline" (click)="genericSocialShare('fb')">
                                                        <span class="hide"></span>
                                                        <i class="fa fa-facebook"></i>
                                                    </a>
                                                    <a href="javascript:void(0)" class="custom-thm8-outline" (click)="genericSocialShare('twitter')">
                                                        <span class="hide"></span>
                                                        <i class="fa fa-twitter"></i> 
                                                    </a>
                                                    <a  href="javascript:void(0)" class="custom-thm8-outline" (click)="genericSocialShare('linkedin')">
                                                        <span class="hide"></span>
                                                        <i class="fa fa-linkedin"></i> 
                                                    </a> 
                                                </div>
                                        </div>
                                </div>-->
                           </div>
                        </div>
                    </div>
                    </div>    
                <div class="col-md-2 pd-lft-0 mrgn-tp-btm"><div class="job-apply-btn">
                    <button mat-raised-button color="primary" (click)="onApplyClick()">Apply</button>
            </div>
            
            </div>
        </div></div>
    </div>
    <div class="container">
        <div class="job-details">
            <lib-job-view (jobViewLoad)="onJobViewLoad($event)"></lib-job-view>
        </div>
        <div class="row details-footer" #target>
            <div class="col-md-12">
                <button mat-raised-button color="primary" (click)="onApplyClick()">Apply</button>
                <button mat-raised-button color="primary" (click)="onBackClick()">Back</button>
            </div>
        </div>
        <div class="container">
            <div *ngIf="loadApplyForm" class="apply-form">
              <div class="row">
                <div class="col-md-8">
                    <lib-job-apply class="scrollable-div" (applyTrigger)="loaderLoad = true" [isTncRequired]="true" (jobSuggestions)="getJobSuggestions($event)" (applyResponse)="onJobSubmission($event)" (applyDataLoaded)="scrollToApply($event)"></lib-job-apply>
                </div>
                               <div *ngIf="jobSuggestions.length > 0" class="col-md-4 jb-rec-jbview pd-22rem">
                  <p class="job-rec-p text-center">
                    Based on your profile, you may want to check the below open positions before submitting
                </p>
                  <lib-job-recommendation themeName="zm-custom-theme" [jobs]="jobSuggestions" (jobURL)="setJobURL($event)"></lib-job-recommendation>
                </div>
              </div>
            </div>
        </div>
    </div>
</section>
<div [appLoading]="loaderLoad" class="loader-tranporent"></div>

<!--common-->
<div class="row custom-row-defthm">
    <div class="col-md-2 custom-labl-defthm">
    </div>
    <div class="col-md-8 custom-inpt-defthm" ng-init="policyCheckbox=false">
        <div id="readPolicy" class="modal fade" role="dialog">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                      <h4>Privacy Notice for Employment Candidates</h4>
                      <button type="button" class="close" type="button" data-bs-dismiss="modal">×</button>
                    </div>
                    <div class="modal-body">
                        <div class="policy">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="policy-content">
                                        <div class="panel-body" id="agreement">    
                                          <div class="read-policy-div">
                                            <p> As part of its recruitment process, ITC Infotech India Limited, its subsidiaries and branches (hereinafter referred to as “ITC Infotech” or “we” or “our” or “us”) collects and processes personal data relating to job applicants (hereinafter referred to as “you” or “your”). ITC Infotech is committed to being transparent about how it collects and uses personal data and to protect the personal data in accordance with data protection laws. </p>
                                            <p> The purpose of this notice is to make sure you are aware of what personal data we collect, how we use and take care of your personal data. This Privacy Notice describes our privacy policies and practices regarding the collection and use of your personal data, and sets out your privacy rights. </p>
                                            <h3>1. Personal data we collect</h3>
                                            <p>We, at ITC Infotech, may collect certain personal data directly from you as well as from other available sources to the extent relevant and permitted by applicable local law.</p>
                                            <p>The categories of personal data that are typically collected and processed in the recruitment context are:</p>
                                            <ul>
                                              <li>Name, address, telephone number, email address, date of birth and other contact details;</li>
                                              <li>Competences, skills, experience and education, e.g., your CV or resume, previous employments, educational details and qualifications, third party references.</li>
                                              <li>health such as disability status in order to make any necessary accommodations during our interview process</li>
                                              <li>Languages spoken, Citizenship and Nationality.</li>
                                              <li>Preferences, e.g. preferred country of employment, areas of interest as well as your preferred ways to be contacted by ITC Infotech);</li>
                                              <li>Your online user identity, e.g. user names and other such information used in connection with authenticating you to our online recruitment management system. </li>
                                              <li> Technical Information: When you access our services online, our web servers automatically create records of your visit. Please read our website privacy policy by visiting <a href="https://www.itcinfotech.com/privacy-policy/" class="a-tag">https://www.itcinfotech.com/privacy-policy</a> for more information; </li>
                                              <!-- <li>Technical Information: When you access our services online , our web servers
                                                                                                                                                  automatically create records of your visit . Please read our website privacy policy
                                                                                                                                                  by visiting <a href="https://www.itcinfotech.com/privacy-policy/">https://www.itcinfotech.com/privacy-policy/</a>  for more information.</li> -->
                                              <li> Information collected during interviews: As part of our interview process, we may maintain audio/ video records of the interview (in case of telephone interviews or video enabled interviews) as well as comments noted by our interviewers. </li>
                                              <li>Other information, such as information found from public sources as well as information collected as part of reference checks, depending on the position you are applying for, and where necessary for the recruitment activities.</li>
                                              <li> Additionally, once you have been shortlisted, we may collect additional information such as Government ID, expectation of compensation and remuneration details, information relating to your family members (for benefits administration), passport, visa and work permit (in case of expatriate), background check reports including educational, employment and criminal checks etc. where permitted by local law. Also, in certain cases, we may collect your bank account details, details of the expense incurred and relevant bills to reimburse any expenses incurred by you during the interview processes. </li>
                                            </ul>
                                            <p>The personal data will be collected by us in a number of ways through multiple channels during the recruitment process and in relation to the employment opportunity with ITC Infotech:</p>
                                            <ul>
                                              <li>Directly from you (When you apply online or via email or in person)</li>
                                              <li>Through referrals from your friends, family of professional connects who work at/ with ITC Infotech</li>
                                              <li>From third parties (through recruitment agencies and background verification agencies), which may also include public sources such as professional networking platforms, social media platforms etc.</li>
                                            </ul>
                                            <p>We do not collect any sensitive personal data (by which we mean either special category personal data or data relating to criminal convictions and offences) from you. In future if we need to collect any sensitive personal data for recruitment process, we will provide you with the relevant information and seek your consent for the processing.</p>
                                            <h3>2. Purposes of processing your personal data and the legal basis for processing</h3>
                                            <p> We, at ITC Infotech, are required to collect and process your personal data for the purposes of recruitment or resourcing activities. When you apply for a role with ITC Infotech you do so voluntarily and can at any time withdraw your application. Your personal data shall be utilized in the following ways:</p>
                                            <ul>
                                              <li>
                                                <strong> Communicating with you,</strong> in context of recruitment activities in accordance with the legitimate business interests of the company: <ul>
                                                  <li>To obtain additional information about your suitability for the role and working location;</li>
                                                  <li>To inform you of available vacancies;</li>
                                                  <li>To provide you with information relating to your application and to fulfil your requests.</li>
                                                </ul>
                                              </li>
                                              <li>
                                                <strong> Managing recruitment and resourcing activities,</strong> including activities related to organizational planning. In the course of recruitment activities, we may use your information, in accordance with our legitimate business interests: <ul>
                                                  <li>To set up and conduct interviews and assessments;</li>
                                                  <li>To evaluate your application;</li>
                                                  <li>To contact third party referees provided by you to evaluate your previous performances;</li>
                                                  <li>In the event your profile is shortlisted, we may collect additional personal data (as mentioned in the previous section), to carry out background checks (in accordance with local law), facilitate travel for face to face interviews and to roll-out an employment offer letter. </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <strong> Monitoring and development of our recruitment process </strong>- We may use your personal data to develop and improve our recruitment processes, websites and other related services. This is in accordance with our legitimate business interests to improve the services we provide. We may use aggregated anonymous information in the context of such development activities.
                                              </li>
                                              <li>
                                                <strong> Audit Compliance </strong>- We may process personal data as part of our audit processes and engage third party auditors, from time to time for our legitimate business interests and legal compliance.
                                              </li>
                                              <li>
                                                <strong> Accommodation for disabilities </strong>- In certain instances we may receive or request for information related to health such as disability status in order to make any necessary accommodations during our interview process.
                                              </li>
                                              <li>
                                                <strong> Prevention of fraud </strong>– We may process your personal data for the purpose of fraud prevention in pursuit of the legitimate interests of the company.
                                              </li>
                                              <li>
                                                <strong>New employment opportunities</strong> we may retain relevant documents collected during the recruitment process containing your personal data for future employment related opportunities
                                              </li>
                                            </ul>
                                            <p> In the future, if we intend to process your personal data for a purpose other than that mentioned above, we will provide you with the relevant information and obtain your consent where it is necessary to do so. </p>
                                            <!-- <p>If you do not provide the information requested as part of the recruitment process, we will be unable to
                                                                                                                                                             provide or register you for the position you are being looked to for being hired.</p> -->
                                            <h3>3. Security</h3>
                                            <p>We have implemented industry standard security measures to keep your personal data secure and confidential, including and not limited to: </p>
                                            <ul>
                                              <li> Limiting access to your personal data, to the persons as identified in this notice, strictly on a need to know basis, such as to respond to your inquiry or request. </li>
                                              <li>Implemented physical, electronic, administrative, technical and procedural safeguards that comply with all applicable laws and regulations to protect your personal data from unauthorized or inappropriate access, alteration, disclosure and destruction. It is important for you to protect against unauthorized access to your password and to your computer. Be sure to sign off when you are done using a shared computer. </li>
                                            </ul>
                                            <h3>4. Who we may share your personal data with (the recipients or categories of recipients of the personal data) </h3>
                                            <ul>
                                              <li> We may use carefully selected third parties to carry out certain activities to help us to run our business (such as cloud service providers, IT support vendors), to facilitate language tests (language assessment agencies), to carry out background checks (background verification agencies) to facilitate your travel and expense (travel and immigration vendors) and to facilitate audits (third party auditors). </li>
                                              <li> We have offices and operations in a number of international locations and we share information between our branch offices and group companies for business and administrative purposes. Your information could be shared with our internal staff for screening and interview purposes. </li>
                                              <li> Where required for your role, your CV’s and business contact details may be shared with customers. </li>
                                              <li>Where required or permitted by law, information may be provided to others, such as regulators and law enforcement agencies. </li>
                                              <li>From time to time, we may consider corporate transactions such as a merger, acquisition, reorganisation, asset sale, or other similar transaction. In these instances, we may transfer or allow access to information to enable the assessment and undertaking of that transaction. If we buy or sell any business or assets, personal data may be transferred to a third party involved in the transaction.</li>
                                            </ul>
                                            <h3>5. International and group company transfers of personal data</h3>
                                            <p> We are part of an international group of companies and, as such, transfer personal data concerning you to countries outside the European Union (EU). Please see our website <a href="https://www.itcinfotech.com" class="a-tag">www.itcinfotech.com</a> for details of the locations and our group companies. </p>
                                            <!-- <p>We are part of an international group of companies and, as such, transfer personal
                                                                                                                                                                      data concerning you to countries outside the European Union (EU). Please see our
                                                                                                                                                                      website <a href="www.itcinfotech.com">www.itcinfotech.com</a> for details of the locations and our group companies.</p> -->
                                            <p>We transfer personal data between our group companies and data centres for the purposes described above. We may also transfer personal data to our third party suppliers outside of the EU as described above.</p>
                                            <p> Your personal data may be stored in databases located outside of the EU including in India. The database is controlled by our administrative staff located outside of the EU including in India and can be accessed electronically. </p>
                                            <p>Where we transfer personal data outside of EU, we either transfer personal data to countries that provide an adequate level of protection (as determined by the European Commission) or we have appropriate safeguards in place. Appropriate safeguards to cover these transfers are in the form of standard contractual/data protection clauses adopted by the European Commission. </p>
                                            <p> If you would like more information on the any of the data transfer mechanisms on which we rely, please contact our Data Protection Officer, details available in the contact section below. </p>
                                            <h3>6. Period for which the personal data will be stored </h3>
                                            <p> Your personal data will be retained as per our Data Retention and Disposal policy unless your application is under process for recruitment. We retain your personal data for that period on our file to enable us to contact you in future about ongoing and potential employment opportunities at ITC Infotech. After this period, we will securely destroy your personal data in accordance with our data retention policy. You may choose to opt-out at any time by writing to us at <a href="mailto:Recruitment.Managers@ITCInfotech.com" class="a-tag">Recruitment.Managers@ITCInfotech.com</a> Once you opt-out ITC Infotech will stop processing your personal data and delete it from our systems and hard copy documents. We will not be able to process your job application for any recruitment activities thereafter. </p>
                                            <!-- <p>
                                                                                                                                                                      Your personal data will be retained for a period of twenty-four (24) months, from your last updated
                                                                                                                                                                      application date, unless your application is under process for recruitment. We retain your personal data for
                                                                                                                                                                      that period on our file to enable us to contact you in future about ongoing and potential employment
                                                                                                                                                                      opportunities at ITC Infotech. After this period, we will securely destroy your personal data in accordance
                                                                                                                                                                      with our data retention policy. You may choose to opt-out at any time by writing to us at
                                                                                                                                                                      <a href="mailto:Recruitment.Managers@ITCInfotech.com">Recruitment.Managers@ITCInfotech.com</a> Once you opt-out ITC Infotech will stop processing your personal
                                                                                                                                                                      data and delete it from our systems and hard copy documents. We will not be able to process your job
                                                                                                                                                                      application for any recruitment activities thereafter.
                                                                                                                                                                     </p> -->
                                            <p> For candidates whose application is successful, we shall retain the personal data in line with our Corporate Data Retention policies. </p>
                                            <!-- <h3> 7. Your rights</h3><p>You may have certain rights relating to your personal data under applicable law such as the right to:</p><ul><li>Request access to your personal data and request details of the processing activities conducted by
                                                                                                                                                                                 ITC Infotech.</li><li>Request that your personal data is rectified if it is inaccurate, out-of-date, irrelevant, misleading, or
                                                                                                                                                                                 incomplete</li></ul><p>We do not carry out any processing activities that involve automated decisions. </p> -->
                                            <h3>7. Your rights </h3>
                                            <p>You have a right to:</p>
                                            <ul>
                                              <li>Request access to your personal data and request details of the processing activities conducted by ITC Infotech.</li>
                                              <li>Request that your personal data is rectified if it is inaccurate or incomplete</li>
                                              <li>Request erasure of your personal data in certain circumstances.</li>
                                              <li>Request restriction of the processing of your personal data by ITC Infotech in certain circumstances.</li>
                                              <li>Object to the processing of your personal data in certain circumstances.</li>
                                              <li>Receive your personal data in a structured, commonly used and machine -readable format in certain circumstances.</li>
                                              <li>Lodge a complaint with the relevant supervisory authority.</li>
                                              <li>Object to, and not to be subject to a decision based solely on, automated processing (including profiling), which produces legal effects or significantly affects you.</li>
                                              <li>Withdraw any consent you have provided to us at any time by contacting us.</li>
                                            </ul>
                                            <p>To exercise the rights outlined above in respect of your personal data you may submit a data subject request on our candidate portal the details of which has been sent to you via email on receiving your application. In case you face any issues in accessing our portal, you may also write to us at <a href="mailto:DPO@itcinfotech.com" class="a-tag">DPO@itcinfotech.com.</a>
                                            </p>
                                            <h3>8. Contact</h3>
                                            <p>If you have any questions or concerns about our privacy policy or your personal data that we process, or about the recruitment process, please contact us <a href="mailto:Recruitment.Managers@ITCInfotech.com" class="a-tag">Recruitment.Managers@ITCInfotech.com</a> . </p>
                                            <!-- <p>Our Privacy Policy is available at <a href="https://www.itcinfotech.com/"> www.itcinfotech.com </a> and contains information:</p><ul><li>on your rights including how you may access, and seek correction of, your personal data; and</li><li>
                                                                                                                                                                                          how you may complain about a privacy breach, including a breach of the Australian Privacy Principles.
                                                                                                                                                                                      </li></ul> -->
                                            <!-- <p>If you have any questions or concerns about the recruitment process, please contact
                                                                                                                                                                                      us at <a href="mailto:Recruitment.Managers@ITCInfotech.com">Recruitment.Managers@ITCInfotech.com</a></p> -->
                                          </div>
                                        </div>
                                    </div>																						
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <!--common-->
  <!--australia-->
<div class="row custom-row-defthm">
  <div class="col-md-2 custom-labl-defthm">
  </div>
  <div class="col-md-8 custom-inpt-defthm" ng-init="policyCheckbox=false">
      <div id="readPolicy-1" class="modal fade" role="dialog">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <div class="modal-header">
                    <h4>Privacy Notice for Employment Candidates (Australia)</h4>
                    <button type="button" class="close" type="button" data-bs-dismiss="modal">×</button>
                  </div>
                  <div class="modal-body">
                      <div class="policy">
                          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                              <div class="panel panel-default">
                                  <div class="policy-content">
                                      <div class="panel-body" id="agreement">    
                                        <div class="read-policy-div">
                                          <p> As part of its recruitment process, ITC Infotech India Limited, its subsidiaries and branches (hereinafter referred to as “ITC Infotech” or “we” or “our” or “us”) collects and processes personal data (sometimes called personal information in some countries) relating to job applicants (hereinafter referred to as “you” or “your”). ITC Infotech is committed to being transparent about how it collects, holds, uses and discloses personal data and to protect the personal data in accordance with applicable data protection laws. </p>
                                          <p> The purpose of this notice is to make sure you are aware of what personal data we collect, how we use, hold, disclose and take care of your personal data. This Privacy Notice describes our privacy policies and practices regarding the collection and use of your personal data and sets out your privacy rights. </p>
                                          <h3>1. Your Personal Data and Sensitive Personal Data</h3>
                                          <p> We, at ITC Infotech, may collect certain personal data directly from you as well as from other available sources to the extent relevant and permitted by applicable local law. </p>
                                          <p>The categories of personal data that are typically collected and processed in the recruitment context are:</p>
                                          <ul>
                                            <li>Name, address, telephone number, email address, date of birth and other contact details;</li>
                                            <li>Competences, skills, experience and education, e.g., your CV or resume, previous employments, educational details and qualifications, third party references;</li>
                                            <li>health such as disability status in order to make any necessary accommodations during our interview process;</li>
                                            <li>Languages spoken, Citizenship and Nationality;</li>
                                            <li>Preferences, e.g., preferred country of employment, areas of interest as well as your preferred ways to be contacted by ITC Infotech);</li>
                                            <li>Your online user identity, e.g., usernames and other such information used in connection with authenticating you to our online recruitment management system; </li>
                                            <li> Technical Information: When you access our services online, our web servers automatically create records of your visit. Please read our website privacy policy by visiting <a href="https://www.itcinfotech.com/privacy-policy/" class="a-tag">https://www.itcinfotech.com/privacy-policy</a> for more information; </li>
                                            <!-- <li>Technical Information: When you access our services online , our web servers
                                                                                                                                                automatically create records of your visit . Please read our website privacy policy
                                                                                                                                                by visiting <a href="https://www.itcinfotech.com/privacy-policy/">https://www.itcinfotech.com/privacy-policy/</a>  for more information.</li> -->
                                            <li> Information collected during interviews: As part of our interview process, we may maintain audio/ video records of the interview (in case of telephone interviews or video enabled interviews) as well as comments noted by our interviewers; </li>
                                            <li>Other information, such as information found from public sources as well as information collected as part of reference checks, depending on the position you are applying for, and where necessary for the recruitment activities;</li>
                                            <li>Additionally, once you have been shortlisted, we may collect additional information such as Government ID, expectation of compensation and remuneration details, information relating to your family members (for benefits administration), passport, visa and work permit (in case of expatriate), background check reports including educational, employment and criminal checks etc. where permitted by local law. Also, in certain cases, we may collect your bank account details, details of the expense incurred and relevant bills to reimburse any expenses incurred by you during the interview processes. </li>
                                          </ul>
                                          <p>The personal data will be collected by us in a number of ways through multiple channels during the recruitment process and in relation to the employment opportunity with ITC Infotech:</p>
                                          <ul>
                                            <li>Directly from you (When you apply online or via email or in person);</li>
                                            <li>Through referrals from your friends, family of professional connects who work at/ with ITC Infotech;</li>
                                            <li>From third parties (through recruitment agencies and background verification agencies), which may also include public sources such as professional networking platforms, social media platforms etc.</li>
                                          </ul>
                                          <p>We do not collect any sensitive personal data (by which we mean either special category personal data or data relating to criminal convictions and offences or “sensitive information” as that term is defined in the Privacy Act 1988 (Cth)) from you. In future if we need to collect any sensitive personal data for recruitment process, we will provide you with the relevant information and seek your consent for the processing.</p>
                                          <h3>2. Purposes of collecting and processing your personal data and the legal basis for processing</h3>
                                          <p> We, at ITC Infotech, are required to collect and process your personal data for the purposes of recruitment or resourcing activities. When you apply for a role with ITC Infotech you do so voluntarily and can at any time withdraw your application. Your personal data shall be utilized in the following ways:</p>
                                          <ul>
                                            <li>
                                              <strong> Communicating with you,</strong> in context of recruitment activities in accordance with the legitimate business interests of the company: <ul>
                                                <li>To obtain additional information about your suitability for the role and working location;</li>
                                                <li>To inform you of available vacancies;</li>
                                                <li>To provide you with information relating to your application and to fulfil your requests.</li>
                                              </ul>
                                            </li>
                                            <li>
                                              <strong> Managing recruitment and resourcing activities,</strong> including activities related to organizational planning. In the course of recruitment activities, we may use your information, in accordance with our legitimate business interests: <ul>
                                                <li>To set up and conduct interviews and assessments;</li>
                                                <li>To evaluate your application;</li>
                                                <li>To contact third party referees provided by you to evaluate your previous performances;</li>
                                                <li>In the event your profile is shortlisted, we may collect additional personal data (as mentioned in the previous section), to carry out background checks (in accordance with local law), facilitate travel for face-to-face interviews and to roll-out an employment offer letter. </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <strong> Monitoring and development of our recruitment process </strong>- We may use your personal data to develop and improve our recruitment processes, websites, and other related services. This is in accordance with our legitimate business interests to improve the services we provide. We may use aggregated anonymous information in the context of such development activities.
                                            </li>
                                            <li>
                                              <strong> Audit Compliance </strong>- We may process personal data as part of our audit processes and engage third party auditors, from time to time for our legitimate business interests and legal compliance.
                                            </li>
                                            <!-- <li><strong> Accommodation for disabilities </strong>- In certain instanc es we may receive or
                                                                                                                                                            request for information related to health such as disability status in order to make
                                                                                                                                                            any necessary accommodations during our interview process . </li><li><strong> Prevention of fraud </strong>– We may process your personal data for the purpose of
                                                                                                                                                            fraud prevention in pursuit of the legitimate interests of the company .</li><li><strong>New employment opportunities</strong>  we may retain relevant documents collected
                                                                                                                                                            during the recruitment process containing your personal data for future
                                                                                                                                                            employment related opportunities
                                                                                                                                                            </li> -->
                                          </ul>
                                          <p> In the future, if we intend to process your personal data for a purpose other than that mentioned above, we will provide you with the relevant information and obtain your consent where it is necessary to do so. </p>
                                          <p>If you do not provide the information requested as part of the recruitment process, we will be unable to provide or register you for the position you are being looked to for being hired.</p>
                                          <h3>3. Security</h3>
                                          <p>We have implemented industry standard security measures to keep your personal data secure and confidential, including and not limited to: </p>
                                          <ul>
                                            <li> Limiting access to your personal data, to the persons as identified in this notice, strictly on a need- to-know basis, such as to respond to your inquiry or request. </li>
                                            <li>Implemented physical, electronic, administrative, technical, and procedural safeguards that comply with all applicable laws and regulations to protect your personal data from misuse, interference and loss and unauthorized or inappropriate access, alteration, modification, disclosure and destruction. It is important for you to protect against unauthorized access to your password and to your computer. Be sure to sign off when you are done using a shared computer. </li>
                                          </ul>
                                          <h3>4. Who we may share your personal data with (the recipients or categories of recipients of the personal data) </h3>
                                          <ul>
                                            <li> We may use carefully selected third parties to carry out certain activities to help us to run our business (such as cloud service providers, IT support vendors), to facilitate language tests (language assessment agencies), to carry out background checks (background verification agencies) to facilitate your travel and expense (travel and immigration vendors) and to facilitate audits (third party auditors). </li>
                                            <li> We have offices and operations in a number of international locations and we share information between our branch offices and group companies for business and administrative purposes. Your information could be shared with our internal staff for screening and interview purposes. </li>
                                            <li> Where required for your role, your CV’s and business contact details may be shared with customers. </li>
                                            <li>Where required or permitted by law, information may be provided to others, such as regulators and law enforcement agencies. </li>
                                            <li>From time to time, we may consider corporate transactions such as a merger, acquisition, reorganisation, asset sale, or other similar transaction. In these instances, we may transfer or allow access to information to enable the assessment and undertaking of that transaction. If we buy or sell any business or assets, personal data may be transferred to a third party involved in the transaction.</li>
                                          </ul>
                                          <h3>5. International and group company transfers of personal data</h3>
                                          <p> We are part of an international group of companies and, as such, transfer personal data concerning you to countries outside of your place of residence such as the European Union (EU) or Australia. Please see our website <a href="https://www.itcinfotech.com" class="a-tag">www.itcinfotech.com</a> for details of the locations and our group companies. </p>
                                          <!-- <p>We are part of an international group of companies and, as such, transfer personal
                                                                                                                                                                    data concerning you to countries outside the European Union (EU). Please see our
                                                                                                                                                                    website <a href="www.itcinfotech.com">www.itcinfotech.com</a> for details of the locations and our group companies.</p> -->
                                          <p>We transfer personal data between our group companies and data centres for the purposes described above. We may also transfer personal data to our third-party suppliers outside of your place of residence such as the EU or Australia as described above.</p>
                                          <p> Your Personal Data may be transferred, processed and retained in Australia, UK, European Economic Area (EEA), Switzerland, South Africa, Hong Kong, Canada, UAE, Brazil, Singapore and the US, and India. These may also be accessed electronically from these locations. </p>
                                          <p> The database is controlled by our administrative staff located outside of the EU and Australia including in India and can be accessed electronically. </p>
                                          <p>Where we transfer personal data outside of EU or Australia, we either transfer personal data to countries that provide an adequate level of protection (as determined by the European Commission) or we have appropriate safeguards in place. Appropriate safeguards to cover these transfers are in the form of standard contractual/data protection clauses adopted by the European Commission or obtaining an enforceable contractual commitment from the relevant overseas recipient to comply with the Australian Privacy Principles (as applicable). </p>
                                          <p> If you would like more information on the any of the data transfer mechanisms on which we rely, please contact our Data Protection Officer, details available in the contact section below. </p>
                                          <h3>6. Period for which the personal data will be stored </h3>
                                          <p> Your personal data will be retained for a period of twenty-four (24) months, from your last updated application date, unless your application is under process for recruitment. We retain your personal data for that period on our file to enable us to contact you in future about ongoing and potential employment opportunities at ITC Infotech. After this period, we will securely destroy your personal data in accordance with our data retention policy. You may choose to opt-out at any time by writing to us at <a href="mailto:Recruitment.Managers@ITCInfotech.com" class="a-tag">Recruitment.Managers@ITCInfotech.com</a> Once you opt-out ITC Infotech will stop processing your personal data and delete it from our systems and hard copy documents. We will not be able to process your job application for any recruitment activities thereafter. </p>
                                          <!-- <p>
                                                                                                                                                                    Your personal data will be retained for a period of twenty-four (24) months, from your last updated
                                                                                                                                                                    application date, unless your application is under process for recruitment. We retain your personal data for
                                                                                                                                                                    that period on our file to enable us to contact you in future about ongoing and potential employment
                                                                                                                                                                    opportunities at ITC Infotech. After this period, we will securely destroy your personal data in accordance
                                                                                                                                                                    with our data retention policy. You may choose to opt-out at any time by writing to us at
                                                                                                                                                                    <a href="mailto:Recruitment.Managers@ITCInfotech.com">Recruitment.Managers@ITCInfotech.com</a> Once you opt-out ITC Infotech will stop processing your personal
                                                                                                                                                                    data and delete it from our systems and hard copy documents. We will not be able to process your job
                                                                                                                                                                    application for any recruitment activities thereafter.
                                                                                                                                                                   </p> -->
                                          <p> For candidates whose application is successful, we shall retain the personal data in line with our Corporate Data Retention policies. </p>
                                          <!-- <h3> 7. Existence of Automated Profiling and Decision Making</h3><p>We use automated profiling, in limited circumstances as explained below:</p><ul><li>In certain instances, while hiring individuals for senior leadership roles, we utilise tools that
                                                                                                                                                                               aggregate publicly available data about the individual, analyses the text content, detects
                                                                                                                                                                               communication style, makes personality assumptions, and provides personality predictions, based
                                                                                                                                                                               on the person's writing style. This information shall be utilized by the recruitment team pursuant to
                                                                                                                                                                               our legitimate business interest in order to tailor our interview processes and communicate better
                                                                                                                                                                               with the candidate.</li><li>While hiring individuals for entry level positions, we may utilise web-based tool to facilitate the
                                                                                                                                                                               interview & recruitment process. The tool maintains video records of the interview process. These
                                                                                                                                                                               insights of the profiling are utilised by the recruitment team pursuant to our legitimate business
                                                                                                                                                                               interest in order to help us take informed decisions while shortlisting our candidates.</li></ul><p>We do not carry out any processing activities that involve automated decisions. </p> -->
                                          <h3>7. Your rights</h3>
                                          <p>You may have certain rights relating to your personal data under applicable law such as the right to:</p>
                                          <ul>
                                            <li>Request access to your personal data and request details of the processing activities conducted by ITC Infotech.</li>
                                            <li>Request that your personal data is rectified if it is inaccurate, out-of-date, irrelevant, misleading, or incomplete</li>
                                            <li>Request erasure of your personal data in certain circumstances.</li>
                                            <li>Request restriction of the processing of your personal data by ITC Infotech in certain circumstances.</li>
                                            <li>Object to the processing of your personal data in certain circumstances.</li>
                                            <li>Receive your personal data in a structured, commonly used and machine-readable format in certain circumstances.</li>
                                            <li>Lodge a complaint with the relevant supervisory authority.</li>
                                            <li>Object to, and not to be subject to a decision based solely on, automated processing (including profiling), which produces legal effects or significantly affects you.</li>
                                            <li>Withdraw any consent you have provided to us at any time by contacting us.</li>
                                          </ul>
                                          <p>To exercise the rights outlined above in respect of your personal data you may submit a data subject request on our candidate portal the details of which has been sent to you via email on receiving your application. In case you face any issues in accessing our portal, you may also write to us at <a href="mailto:DPO@itcinfotech.com" class="a-tag">DPO@itcinfotech.com.</a>
                                          </p>
                                          <h3>8. Contact</h3>
                                          <p> If you have any questions or concerns about our privacy policy, the recruitment process or your personal data that we process, please contact us <a href="mailto:Recruitment.Managers@ITCInfotech.com" class="a-tag">Recruitment.Managers@ITCInfotech.com</a>
                                          </p>
                                          <p>Our Privacy Policy is available at <a href="https://www.itcinfotech.com" class="a-tag"> www.itcinfotech.com </a> and contains information: </p>
                                          <ul>
                                            <li>on your rights including how you may access, and seek correction of, your personal data; and</li>
                                            <li> how you may complain about a privacy breach, including a breach of the Australian Privacy Principles. </li>
                                          </ul>
                                          <!-- <p>If you have any questions or concerns about the recruitment process, please contact
                                                                                                                                                                                    us at <a href="mailto:Recruitment.Managers@ITCInfotech.com">Recruitment.Managers@ITCInfotech.com</a></p> -->
                                        </div>
                                      </div>
                                  </div>																						
                              </div> 
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!--australia-->
<!--south-africa-->
  <div class="row custom-row-defthm">
    <div class="col-md-2 custom-labl-defthm">
    </div>
    <div class="col-md-8 custom-inpt-defthm" ng-init="policyCheckbox=false">
        <div id="readPolicy-2" class="modal fade" role="dialog">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                      <h4>Privacy Notice for Employment Candidates (South Africa)</h4>
                      <button type="button" class="close" type="button" data-bs-dismiss="modal">×</button>
                    </div>
                    <div class="modal-body">
                        <div class="policy">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="policy-content">
                                        <div class="panel-body" id="agreement">    
                                          <div class="read-policy-div">
                                            <p> As part of its recruitment process, ITC Infotech India Limited, its subsidiaries and branches (hereinafter referred to as “ITC Infotech” or “we” or “our” or “us”) collects and processes personal data relating to job applicants (hereinafter referred to as “you” or “your”). ITC Infotech is committed to being transparent about how it collects and uses personal data and to protect the personal data in accordance with data protection laws. </p>
                                            <p> The purpose of this notice is to make sure you are aware of what personal data we collect, how we use and take care of your personal data. This Privacy Notice describes our privacy policies and practices regarding the collection and use of your personal data, and sets out your privacy rights. </p>
                                            <h3>1. Personal data we collect</h3>
                                            <p> We, at ITC Infotech, may collect certain personal data directly from you as well as from other available sources to the extent relevant and permitted by applicable local law. </p>
                                            <p>The categories of personal data that are typically collected and processed in the recruitment context include:</p>
                                            <ul>
                                              <li>Name, address, telephone number, email address, date of birth and other contact details.</li>
                                              <li>Competences, skills, experience and education, e.g., your CV or resume, previous employments, educational details and qualifications, third party references.</li>
                                              <li>health such as disability status to make any necessary accommodations during our interview process</li>
                                              <li>Languages spoken, Citizenship and Nationality.</li>
                                              <li>Preferences, e.g. preferred country of employment, areas of interest as well as your preferred ways to be contacted by ITC Infotech) </li>
                                              <li>Your online user identity, e.g., user names and other such information used in connection with authenticating you to our online recruitment management system. </li>
                                              <li>Technical Information: When you access our services online , our web servers automatically create records of your visit . Please read our website privacy policy by visiting <a href="https://www.itcinfotech.com/privacy-policy/" class="a-tag">https://www.itcinfotech.com/privacy-policy/</a> for more information. </li>
                                              <li> Information collected during interviews: As part of our interview process, we may maintain audio/ video records of the interview (in case of telephone interviews or video enabled interviews) as well as comments noted by our interviewers. </li>
                                              <li> Other information, such as information found from public sources as well as information collected as part of reference checks, depending on the position you are applying for, and where necessary for the recruitment activities. </li>
                                              <li> Additionally, once you have been shortlisted, we may collect information such as Government ID, expectation of compensation and remuneration details, information relating to your family members (for benefits administration), passport, visa and work permit (in case of expatriate), background check reports including educational, employment and criminal checks etc. as permitted by local law. Also, in certain cases, we may collect your bank account details, details of the expense incurred and relevant bills to reimburse any expenses incurred by you during the interview processes. </li>
                                            </ul>
                                            <p>The personal data will be collected by us in several ways through multiple channels during the recruitment process and in relation to the employment opportunity with ITC Infotech:</p>
                                            <ul>
                                              <li>Directly from you (When you apply online or via email or in person)</li>
                                              <li>Through referrals from your friends, family of professional connects who work at/ with ITC Infotech</li>
                                              <li>From third parties (through recruitment agencies and background verification agencies), which may also include public sources such as professional networking platforms, social media platforms etc.</li>
                                            </ul>
                                            <p> In most instances, you are required to make your personal data available, and the supply of the personal data is accordingly mandatory. In instances where it is voluntary, we will inform you accordingly. Should you fail to provide the necessary personal data, we may not be able to process your application for employment. </p>
                                            <p> In order to ensure that the personal data held by us is accurate, you are required to inform the Company immediately in the event that your personal data changes, e.g., qualifications, change of marital status, address, telephone number, etc. </p>
                                            <p>We do not collect any sensitive personal data (by which we mean either special category personal data or data relating to criminal convictions and offences) from you. In future if we need to collect any sensitive personal data for recruitment process, we will provide you with the relevant information and seek your consent for the processing.</p>
                                            <h3>2. Purposes of processing your personal data and the legal basis for processing</h3>
                                            <p> We, at ITC Infotech, are required to collect and process your personal data for the purposes of recruitment or resourcing activities. When you apply for a role with ITC Infotech you do so voluntarily and can at any time withdraw your application. Your personal data shall be utilized in the following ways: </p>
                                            <ul>
                                              <li>
                                                <strong> Communicating with you,</strong> in context of recruitment activities in accordance with the legitimate business interests of the company: <ul>
                                                  <li>To obtain additional information about your suitability for the role and working location ;</li>
                                                  <li>To inform you of available vacancies</li>
                                                  <li>To provide you with information relating to your application and to fulfil your requests.</li>
                                                </ul>
                                              </li>
                                              <li>
                                                <strong> Managing recruitment and resourcing activities,</strong> including activities related to organizational planning. During recruitment activities, we may use your information, in accordance with our legitimate business interests: <ul>
                                                  <li>To set up and conduct interviews and assessments</li>
                                                  <li>To evaluate your application</li>
                                                  <li>To contact third party referees provided by you to evaluate your previous performances.</li>
                                                </ul>
                                              <li>In the event your profile is shortlisted, we may collect additional personal data (as mentioned in the previous section), to carry out background checks (in accordance with local law), facilitate travel for face-to-face interviews and to roll-out an employment offer letter. </li>
                                              <li>
                                                <strong> Monitoring and development of our recruitment process </strong>- We may use your personal data to develop and improve our recruitment processes, websites and other related services. This is in accordance with our legitimate business interests to improve the services we provide. We may use aggregated anonymous information in the context of such development activities.
                                              </li>
                                              <li>
                                                <strong> Audit Compliance </strong>- We may process personal data as part of our audit processes and engage third party auditors, from time to time for our legitimate business interests and legal compliance.
                                              </li>
                                              <li>
                                                <strong> Accommodation for disabilities </strong>- In certain instances we may receive or request for information related to health such as disability status in order to make any necessary accommodations during our interview process .
                                              </li>
                                              <li>
                                                <strong> Prevention of fraud </strong>– We may process your personal data for the purpose of fraud prevention in pursuit of the legitimate interests of the company.
                                              </li>
                                              <li>
                                                <strong>New employment opportunities</strong> we may retain relevant documents collected during the recruitment process containing your personal data for future employment related opportunities
                                              </li>
                                              <li>
                                                <strong>Legal proceedings</strong> we may retain relevant personal data should it be required by us for purposes of any legal proceedings.
                                              </li>
                                              <li>
                                                <strong>Legislation</strong> we may be required to process your personal data in terms of applicable legislation which may include, but is not limited to, any relevant employment equity laws, tax laws and occupational health and safety laws.
                                              </li>
                                            </ul>
                                            <p>In the future, if we intend to process your personal data for a purpose other than that mentioned above, we will provide you with the relevant information and obtain your consent where it is necessary to do so.</p>
                                            <!-- <p>If you do not provide the information requested as part of the recruitment process, we will be unable to
                                                                                                                                                              provide or register you for the position you are being looked to for being hired.</p> -->
                                            <h3>3. Security</h3>
                                            <p> We have implemented industry standard security measures to keep your personal data secure and confidential, including and not limited to: </p>
                                            <ul>
                                              <li>Limiting access to your personal data, including to the persons as identified in this notice, strictly on a need-to-know basis, such as to respond to your inquiry or request. </li>
                                              <li> Implemented physical, electronic, administrative, technical, and procedural safeguards that comply with all applicable laws and regulations to protect your personal data from unauthorized or inappropriate access, alteration, disclosure and destruction. It is important for you to protect against unauthorized access to your password and to your computer. Be sure to sign off when you are done using a shared computer. </li>
                                            </ul>
                                            <h3>4. Who we may share your personal data with (the recipients or categories of recipients of the personal data) </h3>
                                            <ul>
                                              <li> We may use carefully selected third parties to carry out certain activities to help us run our business (such as cloud service providers, IT support vendors), to facilitate language tests (language assessment agencies), to carry out background checks (background verification agencies) to facilitate your travel and expense (travel and immigration vendors) and to facilitate audits (third party auditors). </li>
                                              <li> We have offices and operations in several international locations, and we share information between our branch offices and group companies for business and administrative purposes. Your information could be shared with our internal staff for screening and interview purposes. </li>
                                              <li> Where required for your role, your CV’s and business contact details may be shared with customers. </li>
                                              <li>Where required or permitted by law, information may be provided to others, such as regulators and law enforcement agencies. </li>
                                              <li>From time to time, we may consider corporate transactions such as a merger, acquisition, reorganisation, asset sale, or other similar transaction. In these instances, we may transfer or allow access to information to enable the assessment and undertaking of that transaction. If we buy or sell any business or assets, personal data may be transferred to a third party involved in the transaction.</li>
                                            </ul>
                                            <h3>5. International and group company transfers of personal data</h3>
                                            <p> We are part of an international group of companies and, as such, transfer personal data concerning you to countries outside of your country of residence. Please see our website <a href="https://www.itcinfotech.com" class="a-tag">www.itcinfotech.com</a> for details of the locations and our group companies. </p>
                                            <!-- <p>We are part of an international group of companies and, as such, transfer personal
                                                                                                                                                                      data concerning you to countries outside the European Union (EU). Please see our
                                                                                                                                                                      website <a href="www.itcinfotech.com">www.itcinfotech.com</a> for details of the locations and our group companies.</p> -->
                                            <p>We transfer personal data between our group companies and data centres for the purposes described above. We may also transfer personal data to our third-party suppliers outside of your country of residence as described above.</p>
                                            <p> Your personal data may be stored in databases located outside of your country of residence, including in India. The database is controlled by our administrative staff located and can be accessed electronically. </p>
                                            <p> Where we transfer personal data outside your country of residence to a country that is not subject to a law that provides adequate protection, we ensure that the recipient is subject to a binding transfer agreement, binding corporate rules, or standard contractual clauses in respect of transfers outside the EU, that ensures an adequate level of protection in accordance with applicable data protection laws. </p>
                                            <p> If you would like more information on the any of the data transfer mechanisms on which we rely, please contact the Data Protection or Information Officer relevant to your jurisdiction, the details of which are available in the contact section below. </p>
                                            <h3>6. Period for which the personal data will be stored </h3>
                                            <p> Your personal data will be retained for a period of 12 to 18 months, from your last updated application date, unless your application is under process for recruitment. We retain your personal data for that period on our file to enable us to contact you in future about ongoing and potential employment opportunities at ITC Infotech and for our legitimate business purposes. After this period, we will securely destroy your personal data in accordance with our data retention policy, unless otherwise required by law. You may choose to opt-out at any time by writing to us at <a href="mailto:Recruitment.Managers@ITCInfotech.com" class="a-tag">Recruitment.Managers@ITCInfotech.com</a> Once you opt-out ITC Infotech will stop processing your personal data and delete it from our systems and hard copy documents. We will not be able to process your job application for any recruitment activities thereafter. </p>
                                            <!-- <p> Your personal data will be retained for a period of 12 to 18 months, from your last updated
                                                                                                                                                              application date, unless your application is under process for recruitment. We retain your
                                                                                                                                                              personal data for that period on our file to enable us to contact you in future about ongoing and
                                                                                                                                                              potential employment opportunities at ITC Infotech and for our legitimate business purposes.
                                                                                                                                                              After this period, we will securely destroy your personal data in accordance with our data
                                                                                                                                                              retention policy, unless otherwise required by law. You may choose to opt-out at any time by
                                                                                                                                                              writing to us at <a href="mailto:Recruitment.Managers@ITCInfotech.com">Recruitment.Managers@ITCInfotech.com</a>. Once you opt-out ITC Infotech will
                                                                                                                                                              stop processing your personal data and delete it from our systems and hard copy documents.
                                                                                                                                                              We will not be able to process your job application for any recruitment activities thereafter.
                                                                                                                                                                  </p> -->
                                            <p>For candidates whose application is successful, we shall retain the personal data in line with our employment Corporate Data Retention policies.</p>
                                            <h3> 7. Your rights</h3>
                                            <p>You have a right to: </p>
                                            <ul>
                                              <li>Request access to your personal data and request details of the processing activities conducted by ITC Infotech.</li>
                                              <li>Request that your personal data be rectified if it is inaccurate or incomplete.</li>
                                              <li>Request erasure of your personal data in certain circumstances.</li>
                                              <li>Request restriction of the processing of your personal data by ITC Infotech in certain circumstances.</li>
                                              <li>Object to the processing of your personal data in certain circumstances.</li>
                                              <li>Receive your personal data in a structured, commonly used and machine-readable format in certain circumstances.</li>
                                              <li>Lodge a complaint with the relevant supervisory authority.</li>
                                              <li>Object to, and not to be subject to a decision based solely on, automated processing (including profiling), which produces legal effects or significantly affects you.</li>
                                              <li>Withdraw any consent you have provided to us at any time by contacting us.</li>
                                            </ul>
                                            <p> To exercise the rights outlined above in respect of your personal data you may submit a data subject request on our candidate portal the details of which has been sent to you via email on receiving your application. In case you face any issues in accessing our portal, you may also write to us at <a href="mailto:DPO@ITCInfotech.com" class="a-tag">DPO@ITCInfotech.com.</a>
                                            </p>
                                            <!-- <p>
                                                                                                                                                                              To exercise the rights outlined above in respect of your personal data you may submit a data subject request on our candidate portal the details of which has been sent to you via email on receiving your application. 
                                                                                                                                                                              In case you face any issues in accessing our portal, you may also write to us at <a href="mailto:DPO@ITCInfotech.com">DPO@ITCInfotech.com.</a></p>    -->
                                            <!-- <h3>8. Your rights  </h3><p>You may have certain rights relating to your personal data under applicable law such as the right to:</p><ul><li>Request access to your personal data and request details of the processing
                                                                                                                                                                                  activities conducted by ITC Infotech.</li><li>Request that your personal data is rectified if it is inaccurate or incomplete</li><li>Request era sure of your personal data in certain circumstances. </li><li>Request restriction of the processing of your personal data by ITC Infotech in
                                                                                                                                                                                  certain circumstances.</li><li>Object to the processing of your personal data in certain circumstances.</li><li>Receive your personal data in a structured, commonly used and machine - readable
                                                                                                                                                                                  format in certain circumstances .</li><li>Lodge a complaint with the relevant supervisory authority .</li><li>Object to, and not to be subject to a decision based solely on, automated
                                                                                                                                                                                  processing (including profiling), which produces legal effects or significantly
                                                                                                                                                                                  affects you.</li><li>Withdraw any consent you have provided to us at any time by contacting us .</li><p>
                                                                                                                                                                                  To exercise the rights outlined above in respect of your personal data you may submit a data subject request on our candidate portal the details of which has been sent to you via email on receiving your application. 
                                                                                                                                                                                  In case you face any issues in accessing our portal, you may also write to us at <a href="mailto:DPO@ITCInfotech.com">DPO@ITCInfotech.com.</a></p></ul><p>To exercise the rights outlined above in respect of your personal data you may submit
                                                                                                                                                                              a data subject request on our candidate portal the details of which has been sent to
                                                                                                                                                                              you via email on receiving your application. In case you face any issues in accessing
                                                                                                                                                                              our portal, you may also write to us at <a href="mailto:DPO@itcinfotech.com">DPO@itcinfotech.com.</a></p> -->
                                            <h3>8. Contact</h3>
                                            <p> If you have any questions or concerns about our privacy policy or your personal data or about the recruitment process that we process, please contact us <a href="mailto:Recruitment.Managers@ITCInfotech.com" class="a-tag">Recruitment.Managers@ITCInfotech.com</a>. </p>
                                            <!-- <p>If you have any questions or concerns about our privacy policy or your personal data
                                                                                                                                                                                  that we process, please contact us <a href="mailto:Recruitment.Managers@ITCInfotech.com">Recruitment.Managers@ITCInfotech.com</a> .</p> -->
                                            <!-- <p>If you have any questions or concerns about the recruitment process, please contact us at <a href="mailto:Recruitment.Managers@ITCInfotech.com">Recruitment.Managers@ITCInfotech.com</a></p> -->
                                            <!-- <p>If you have any questions or concerns about the recruitment process, please contact
                                                                                                                                                                                      us at <a href="mailto:Recruitment.Managers@ITCInfotech.com">Recruitment.Managers@ITCInfotech.com</a></p> -->
                                          </div>
                                        </div>
                                    </div>																						
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
<!--south-africa-->
<!--usa-->
<div class="row custom-row-defthm">
  <div class="col-md-2 custom-labl-defthm">
  </div>
  <div class="col-md-8 custom-inpt-defthm" ng-init="policyCheckbox=false">
      <div id="readPolicy-4" class="modal fade" role="dialog">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <div class="modal-header">
                    <h4>Privacy Notice for Employment Candidates (For California Residents Only)</h4>
                    <button type="button" class="close" type="button" data-bs-dismiss="modal">×</button>
                  </div>
                  <div class="modal-body">
                      <div class="policy">
                          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                              <div class="panel panel-default">
                                  <div class="policy-content">
                                      <div class="panel-body" id="agreement">    
                                        <div class="read-policy-div">
                                          <p> This notice is applicable to all job applicants and prospective California employees (“Candidates”) of ITC Infotech (USA), Inc.who are residents of California, and whose personal information is collected and/or processed in the context of the employment relationship (known through this document as “you” or “your”). </p>
                                          <p> If a Candidate is hired for employment in California, we will use and retain personal information as described in our California Employee Privacy Notice. </p>
                                          <p> ITC Infotech Group is committed to protecting the privacy and security of your personal information.</p>
                                          <p> ITC Infotech (USA), Inc. (hereinafter referred to as “ITC Infotech” or “Company” or “we” or “our” or “us”), collects, holds, processes and transfers personal information about you as necessary to administer and carry out the recruitment and hiring process. </p>
                                          <p> The purpose of this notice is to provide you information about how we collect, store and process your personal information under the California Consumer Privacy Act (“CCPA”). The notice is not intended to create any rights beyond those that exist by virtue of applicable California privacy and data protection law. </p>
                                          <!-- one -->
                                          <h3>1. PERSONAL INFORMATION COLLECTED</h3>
                                          <p> The information about you that we collect includes information within the below categories of data.. Note that the categories listed below are defined by California state law. Inclusion of a category in the list below indicates only that, depending on your relationship with us, we may collect some information within that category. It does not necessarily mean that we collect all information listed in a particular category about you. Further, please note that some types of personal information may fall into multiple categories. </p>
                                          <p> We do not sell personal information about you, nor do we intend to do so in the future. </p>
                                          <table class="table-responsive">
                                            <thead>
                                              <tr>
                                                <th scope="col">Category of Information Collected</th>
                                                <th scope="col">Source</th>
                                                <th scope="col">Purpose of Processing</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td > Identifiers such as a real name, alias, postal address, personal email address, emergency contact details, account name, social security number, driver’s license number, passport number, Visa Copy (If H1B), DL Copy, Date of Birth, Expiry Date, Emergency Contact Details Emergency Contact Number, Emergency Contact Address in USA or other similar identifiers. </td>
                                                <td>You</td>
                                                <td>This data is necessary to administer and carry out the recruitment and hiring process, including for human resources purposes, and as described in this Notice and below , including meeting our legal obligations.</td>
                                              </tr>
                                              <tr>
                                                <td> Information that identifies, relates to, describes, or is capable of being associated with, a particular individual, including, but not limited to, your name, signature address, telephone number, email id, education, employment, employment history. </td>
                                                <td>
                                                  <p>You</p>
                                                  <p>Recruitment consultants and agencies</p>
                                                  <p>Your previous employers</p>
                                                  <p>Publicly available information from online resources</p>
                                                  <p>Third party benefits providers</p>
                                                </td>
                                                <td> This data is necessary to administer and carry out the recruitment and hiring process, including for human resources purposes, and as described in this Notice and below, including meeting our legal obligations. </td>
                                              </tr>
                                              <tr>
                                                <td> Characteristics of classes protected under federal or California law, including familial status, disability, sex, national origin, religion, color, race, sexual orientation, gender identity and gender expression, marital status, veteran status, medical condition, ancestry, source of income, age, or genetic information. </td>
                                                <td>
                                                  <p>You</p>
                                                  <p>Your emergency contact(s)</p>
                                                  <p>Government run disclosure and barring services or other third parties who provide similar services</p>
                                                </td>
                                                <td> This data is necessary to promote our diversity and inclusion initiatives, and for our establishment, exercise, or defence of legal claims. </td>
                                              </tr>
                                              <tr>
                                                <td> Professional or employment-related information </td>
                                                <td>
                                                  <p>You</p>
                                                  <p>Recruitment consultants and agencies </p>
                                                  <p>Your previous employers</p>
                                                </td>
                                                <td> This data is necessary to administer and carry out the recruitment and hiring process, including for human resources purposes, and as described in this Notice and below , and pursuing the efficient management of our business. </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <!-- two -->
                                          <h3>2. YOUR RIGHTS OVER YOUR PERSONAL INFORMATION</h3>
                                          <p> You have the right to make certain requests regarding your personal information as provided for under the applicable California privacy and data protection law.These include the right to request to: </p>
                                          <ul>
                                            <li> access your personal information, including the categories of personal information we have collected about you and the categories of sources from which the person information is collected; </li>
                                            <li> correct your personal information; </li>
                                            <li> limit the use and disclosure of sensitive personal information; </li>
                                            <li> erase your personal information; </li>
                                            <li> receive your personal information in a usable electronic format and transmit it to a third party (right to data portability); and </li>
                                            <li> lodge a complaint with your local data protection authority. </li>
                                          </ul>
                                          <p> While you also have the right to opt out of the sale of any of your personal information under California law, we do not sell your information. </p>
                                          <p> ITC Infotech will not discriminate against you (e.g., through denying employment) for exercising any of the rights afforded to you. </p>
                                          <p> To exercise certain rights, we will make sure to verify your identity. Please contact the Data Protection Officer at the email address DPO@itcinfotech.com with the following information to verify the request: </p>
                                          <ul>
                                            <li> Full Name: </li>
                                            <li> Email Id: </li>
                                            <li> Data Subject Right you want to exercise: </li>
                                            <li> Relationship with ITC Infotech: </li>
                                            <li> Country of Residence: </li>
                                            <li> City of Residence: </li>
                                            <li> Request Details: </li>
                                          </ul>
                                          <p> or provide the details in this request form through the below link. </p>
                                          <p> Link: <a href="https://www.itcinfotech.com/privacy-rights-request-form/" class="a-tag" target="_blank"> Request Form</a>
                                          </p>
                                          <p> In addition, to exercise your rights mentioned above, you can call <a class="a-tag" href="tel:1-888-882-2037">1-888-882-2037</a>. </p>
                                          <p> If you would like to authorize someone to make a request on your behalf, please ensure we have that information on file, or you must provide us with written, signed permission to submit privacy right requests on your behalf, or provide a letter from your attorney. Your agent or attorney must provide this authorization at the time of request. Note that we may require you to verify your identity with us directly before we provide any requested information to your agent. </p>
                                          <p> We will contact you if we need additional information from you in order to honor and verify your requests. </p>
                                          <p> We will not use automated decision making when we process your data. </p>
                                          <!-- <p>If you do not provide the information requested as part of the recruitment process, we will be unable to
                                                                                                                                                            provide or register you for the position you are being looked to for being hired.</p> -->
                                          <h3>3. INFORMATION SHARING</h3>
                                          <p> We may share your personal information with third parties under the following circumstances: </p>
                                          <ul>
                                            <li> Third party service providers: We may share your personal information with our service providers. </li>
                                            <li> Group companies: We may share certain information with other ITC affiliates, including ITC Infotech India Limited, and its branches and subsidiaries, for the purposes of internal reporting purposes. </li>
                                            <li> Third parties with whom you direct us to share your personal information. </li>
                                            <li> Other parties such as consumer reporting agencies when we perform employee background screenings. </li>
                                            <li> Where required by law: We may share your personal information with law enforcement agencies, courts, other government authorities or other third parties where we believe it is necessary in order to comply with a legal or regulatory obligation, or otherwise to protect our rights or the rights of any third party. </li>
                                            <li> Business transfers: Relying on our legitimate interests, we may share your personal information with any third party that purchases, or to which we transfer, all or substantially all of our assets and business. Should such a sale or transfer occur, we will use reasonable efforts to try to ensure that the entity to which we transfer your personal information uses it in a manner that is consistent with this privacy notice. </li>
                                          </ul>
                                          <h3>4. INFORMATION SECURITY AND STORAGE </h3>
                                          <p> We implement technical and organizational measures to ensure a level of security appropriate to the risk to the personal information we process. These measures are aimed at ensuring the on-going integrity and confidentiality of personal information. We evaluate these measures on a regular basis to ensure the security of the processing. </p>
                                          <h3>5. INTERNATIONAL DATA TRANSFERS</h3>
                                          <p> Your personal information may be transferred to, stored, and processed in a country that is not regarded as ensuring an adequate level of protection for personal information under applicable law. We have put in place appropriate safeguards (such as contractual commitments) in accordance with applicable legal requirements to ensure that your data is adequately protected. For more information on the appropriate safeguards in place, please contact us at the details below. </p>
                                          <h3>6. CONTACTS </h3>
                                          <p> If you have any questions about this privacy notice or how we handle your personal data, please contact the Data Protection Officer at the email address <a href="mailto:DPO@ITCInfotech.com" class="a-tag"> DPO@ITCInfotech.com</a>
                                          </p>
                                          <h3> 7. CHANGES TO THE PRIVACY NOTICE</h3>
                                          <p>We may update this privacy notice from time to time. For instance, there may be changes in the data we collect about you or the purposes for which we use it. </p>
                                          <p> Policy last updated: 10th August 2023 </p>
                                        </div>
                                      </div>
                                  </div>																						
                              </div> 
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!--usa-->
<!--brazil-->
<div class="row custom-row-defthm">
  <div class="col-md-2 custom-labl-defthm">
  </div>
  <div class="col-md-8 custom-inpt-defthm" ng-init="policyCheckbox=false">
      <div id="readPolicy-3" class="modal fade" role="dialog">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <div class="modal-header">
                    <h4>Privacy Notice for Employment Candidates (Aviso de privacidade para candidatos a emprego)</h4>
                    <button type="button" class="close" type="button" data-bs-dismiss="modal">×</button>
                  </div>
                  <div class="modal-body">
                      <div class="policy">
                          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                              <div class="panel panel-default">
                                  <div class="policy-content">
                                    <div class="read-policy-div">
                                      <p> As part of its recruitment process, ITC INFOTECH DO BRASIL LTDA (hereinafter referred to as “ITC Infotech” or “we” or “our” or “us”) collects and processes personal data relating to job applicants (hereinafter referred to as “you” or “your”). ITC Infotech is committed to being transparent about how it collects and uses personal data and to protect the personal data in accordance with data protection laws. </p>
                                      <p> The purpose of this notice is to make sure you are aware of what personal data we collect, how we use and take care of your personal data. This Privacy Notice describes our privacy policies and practices regarding the collection and use of your personal data, and sets out your privacy rights. </p>
                                      <p> ITC Infotech is the data controller, which means that it is the entity that collects your personal data and decides why and how to process them. </p>
                                      <p> Personal data means information related to an identified or identifiable a natural person. </p>
                                      <p> The processing of personal data is governed by The General Data Protection Act - Law n. 13,709/2018 (“LGPD”) </p>
                                      <p> Como parte de seu processo de recrutamento e seleção, a ITC INFOTECH DO BRASIL LTDA (doravante denominada "ITC Infotech" ou "nós" ou "nosso" ou "nós") coleta e processa dados pessoais relativos aos candidatos a emprego (doravante denominados "você" ou "seu"). A ITC Infotech está empenhada em ser transparente sobre como coleta e utiliza dados pessoais e em proteger os dados pessoais de acordo com as leis de proteção de dados. </p>
                                      <p> O objetivo deste aviso é garantir que você esteja ciente de quais dados pessoais processamos, como usamos e como cuidamos de seus dados pessoais. Esta Nota de Privacidade descreve nossas políticas e práticas de privacidade em relação à coleta e uso de seus dados pessoais, e estabelece seus direitos de privacidade. </p>
                                      <p> ITC Infotech é controladora dos dados, o que significa que é a entidade que coleta seus dados pessoais e decide por que e como processá-los. </p>
                                      <p> Dados pessoais significam informações relacionadas a uma pessoa física identificada ou identificável. </p>
                                      <p> O processamento de dados pessoais é regido pela Lei Geral de Proteção de Dados - Lei nº 13.709/2018 ("LGPD"). </p>
                                      <!-- one -->
                                      <h3>Personal data we collect (Dados pessoais que coletamos)</h3>
                                      <p> We, at ITC Infotech, may collect certain personal data directly from you as well as from other available sources to the extent relevant and permitted by applicable local law. </p>
                                      <p> The categories of personal data that are typically collected and processed in the recruitment context are: </p>
                                      <ul>
                                        <li> Name, address, telephone number, email address, date of birth and other contact details; </li>
                                        <li> Competences, skills, experience and education, e.g., your CV or resume, previous employments, educational details and qualifications and third party references (the latter will be consulted only with your consent). </li>
                                        <li> health such as disability status in order to make any necessary accommodations during our interview process </li>
                                        <li> Languages spoken, Citizenship and Nationality. </li>
                                        <li> Preferences, e.g., preferred country of employment, areas of interest as well as your preferred ways to be contacted by ITC Infotech); </li>
                                        <li> Your online user identity, e.g., usernames and other such information used in connection with authenticating you to our online recruitment management system. </li>
                                        <li> Technical Information: When you access our services online, our web servers automatically create records of your visit. Please read our website privacy policy by visiting <a href="https://www.itcinfotech.com/privacy-policy/" target="_blank" class="a-tag">https://www.itcinfotech.com/privacy-policy/</a> for more information. </li>
                                        <li> Information collected during interviews: As part of our interview process we may maintain audio/ video records of the interview (in case of telephone interviews or video enabled interviews) as well as comments noted by our interviewers. </li>
                                        <li> Other information, such as information found from public sources, depending on the position you are applying for, and where necessary for the recruitment activities. </li>
                                        <li> Additionally, once you have been shortlisted, we may collect additional information such as Government ID, expectation of compensation and remuneration details, information relating to your family members (for benefits administration), passport, visa and work permit (in case of expatriate), background check reports including educational, employment and criminal checks etc. where permitted by local law. Also, in certain cases, we may collect your bank account details, details of the expense incurred and relevant bills to reimburse any expenses incurred by you during the interview processes. </li>
                                      </ul>
                                      <p> The personal data will be collected by us in a number of ways through multiple channels during the recruitment process and in relation to the employment opportunity with ITC Infotech: </p>
                                      <ul>
                                        <li> Directly from you (When you apply online or via email or in person) </li>
                                        <li> Through referrals from your friends, family of professional connects who work at/ with ITC Infotech </li>
                                        <li> From third parties (through recruitment agencies and background verification agencies), which may also include public sources such as professional networking platforms, professional social media platforms, like LinkedIn or similars, etc. </li>
                                      </ul>
                                      <p> We only process sensitive personal data when necessary, we will collect your consent only when necessary (by sensitive personal data we mean data regarding racial or ethnic origin, religious belief, political opinion, affiliation to any trade union or to a religious, philosophical or political organization, data relative to health or sex life, genetic or biometric data). </p>
                                      <p> Nós, da ITC Infotech, podemos coletar certos dados pessoais diretamente de você, bem como de outras fontes disponíveis, na medida em que for relevante e permitido pela legislação local aplicável. </p>
                                      <p> As categorias de dados pessoais que são normalmente coletados e processados no contexto do recrutamento e seleção são: </p>
                                      <ul>
                                        <li> Nome, endereço, telefone, e-mail, data de nascimento e outros detalhes de contato; </li>
                                        <li> Competências, habilidades, experiência e educação, por exemplo, seu currículo, empregos anteriores, detalhes e qualificações educacionais e referências de terceiros (estes últimos serão consultados somente mediante seu consentimento). </li>
                                        <li> Saúde, tal como, informações relacionadas a pessoa com deficiência, a fim de fazer as acomodações necessárias durante o processo de entrevista; </li>
                                        <li> Línguas faladas, Cidadania e Nacionalidade. </li>
                                        <li> Preferências, por exemplo, país de emprego preferido, áreas de interesse, bem como suas preferências para ser contatado pela ITC Infotech); </li>
                                        <li> Sua identidade de usuário online, por exemplo, nomes de usuário e outras informações utilizadas em conexão com a autenticação de você em nosso sistema de gerenciamento de recrutamento online. </li>
                                        <li> Informações técnicas: Quando você acessa nossos serviços on-line, nossos servidores web criam automaticamente registros de sua visita. Por favor, leia a política de privacidade de nosso website visitando <a href="https://www.itcinfotech.com/privacy-policy/" target="_blank" class="a-tag">https://www.itcinfotech.com/privacy-policy/</a> para obter mais informações. </li>
                                        <li> Informações coletadas durante as entrevistas: Como parte de nosso processo de entrevista, podemos manter registros de áudio/vídeo da entrevista (no caso de entrevistas por telefone ou entrevistas em vídeo), bem como comentários anotados por nossos entrevistadores. </li>
                                        <li> Outras informações, tais como informações encontradas de fontes públicas, dependendo do cargo ao qual você se candidata e, quando necessário, para as atividades de recrutamento. </li>
                                        <li> Além disso, uma vez que você tenha sido selecionado, poderemos coletar informações adicionais, tais como documentos de identificação pessoal, expectativa de remuneração e seus detalhes, informações relacionadas a seus familiares (para administração de benefícios), passaporte, visto e permissão de trabalho (no caso de expatriado), e, quando permitido pela legislação aplicável, relatórios de checagem de antecedentes, incluindo checagem educacional, de emprego e criminal, etc. Além disso, em certos casos, poderemos recolher os detalhes de sua conta bancária, detalhes das despesas e contas relevantes para reembolsar quaisquer despesas incorridas por você durante os processos de seleção. </li>
                                      </ul>
                                      <p> Os dados pessoais serão coletados por nós de diversas formas através de múltiplos canais durante o processo de recrutamento e seleção e à oportunidade de emprego com a ITC Infotech: </p>
                                      <ul>
                                        <li> Diretamente de você (Quando você se candidatar online ou via e-mail ou pessoalmente); </li>
                                        <li> Através de indicações de seus colegas, profissionais se conecta que trabalham na e/ou com a ITC Infotech; </li>
                                        <li> De terceiros (através de agências de recrutamento e verificação de antecedentes), que também podem incluir fontes públicas, tais como plataformas profissionais de rede, plataformas profissionais de mídia social, como LinkedIn ou similares, etc. </li>
                                        <li> Só processamos dados pessoais sensíveis quando necessário, coletamos seu consentimento somente quando necessário (por dados pessoais sensíveis entendemos dados relativos à origem racial ou étnica, crença religiosa, opinião política, afiliação a qualquer sindicato ou organização religiosa, filosófica ou política, dados relativos à saúde ou à vida sexual, dados genéticos ou biométricos). </li>
                                      </ul>
                                      <!-- two -->
                                      <h3>Purposes of processing your personal data and the legal basis for processing (Objetivos do processamento de seus dados pessoais e a base legal para o processamento)</h3>
                                      <p> We, at ITC Infotech, are required to collect and process your personal data for the purposes of recruitment or resourcing activities. When you apply for a role with ITC Infotech you do so voluntarily and can at any time withdraw your application. Your personal data shall be utilized in the following ways: </p>
                                      <ul>
                                        <li>
                                          <strong>Communicating with you, </strong> in context of recruitment activities in accordance with the legitimate business interests of the company: <ul>
                                            <li> To obtain additional information about your suitability for the role and working location ; </li>
                                            <li> To inform you of available vacancies; </li>
                                            <li> To provide you with information relating to your application and to fulfil your requests. </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <strong>Managing recruitment and resourcing activities,</strong> including activities related to organizational planning. In the course of recruitment activities, we may use your information, in accordance with our legitimate business interests: <ul>
                                            <li> To set up and conduct interviews and assessments; </li>
                                            <li> To evaluate your application; </li>
                                            <li> To contact third party referees provided by you to evaluate your previous performances. </li>
                                          </ul>
                                        </li>
                                        <li> In the event your profile is shortlisted, we may collect additional personal data (as mentioned in the previous section), to carry out background checks (in accordance with local law), facilitate travel for face to face interviews and to rollout an employment offer letter. </li>
                                        <li>
                                          <strong>Monitoring and development of our recruitment process</strong> - We may use your personal data to develop and improve our recruitment processes, websites and other related services. This is in accordance with our legitimate business interests to improve the services we provide. We may use aggregated anonymous information in the context of such development activities.
                                        </li>
                                        <li>
                                          <strong>Audit Compliance</strong> - We may process personal data as part of our audit processes and engage third party auditors, from time to time for our legitimate business interests and legal compliance.
                                        </li>
                                        <li>
                                          <strong> Accommodation for disabilities </strong> - In certain instances we may receive or request for information related to health such as disability status in order to make any necessary accommodations during our interview process.
                                        </li>
                                        <li>
                                          <strong> Prevention of fraud </strong> We may process your personal data for the purpose of fraud prevention in pursuit of the legitimate interests of the company.
                                        </li>
                                        <li>
                                          <strong> New employment opportunities </strong> - we may retain relevant documents collected during the recruitment process containing your personal data for future employment related opportunities
                                        </li>
                                      </ul>
                                      <p> In the future, if we intend to process your personal data for a purpose other than that mentioned above, we will provide you with the relevant information and obtain your consent where it is necessary to do so. </p>
                                      <p> Nós, da ITC Infotech, precisamos coletar e processar seus dados pessoais para fins de atividades de recrutamento ou de recursos. Quando você se candidata a uma função no ITC Infotech, você o faz voluntariamente e pode, a qualquer momento, retirar sua candidatura. Seus dados pessoais serão utilizados das seguintes maneiras:</p>
                                      <ul>
                                        <li>
                                          <strong> Comunicarmos com você, </strong> no contexto das atividades de recrutamento, de acordo com os legítimos interesses comerciais da empresa: <ul>
                                            <li> Para obter informações adicionais sobre sua adequação à função e local de trabalho; </li>
                                            <li> Para informá-lo sobre as vagas disponíveis; </li>
                                            <li> Para lhe fornecer informações relativas à sua candidatura e para atender às suas solicitações. </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <strong> Gerenciar atividades de recrutamento e seleção, </strong> incluindo atividades relacionadas ao planejamento organizacional. No curso do processo seletivo, podemos utilizar suas informações, de acordo com nossos legítimos interesses comerciais: <ul>
                                            <li> Para estabelecer e conduzir entrevistas e avaliações; </li>
                                            <li> Para avaliar sua aplicação; </li>
                                            <li> Para contatar referências profissionais fornecidos por você para avaliar seus desempenhos anteriores. </li>
                                          </ul>
                                        </li>
                                        <li> Caso seu perfil seja selecionado, poderemos coletar dados pessoais adicionais (como mencionado na seção anterior), para realizar verificações de antecedentes (de acordo com a legislação local), facilitar as viagens para entrevistas presenciais e para lançar uma carta de oferta de emprego. </li>
                                        <li>
                                          <strong> Monitoramento e desenvolvimento de nosso processo de recrutamento - </strong> Podemos utilizar seus dados pessoais para desenvolver e melhorar nossos processos de recrutamento, websites e outros serviços relacionados. Isto está de acordo com nossos legítimos interesses comerciais para melhorar os serviços que prestamos. Podemos utilizar informações anônimas agregadas no contexto de tais atividades de desenvolvimento.
                                        </li>
                                        <li>
                                          <strong> Auditoria - </strong> Podemos processar dados pessoais como parte de nossos processos de auditoria e contratar auditores terceirizados, de tempos em tempos para nossos legítimos interesses comerciais e conformidade legal.
                                        </li>
                                        <li>
                                          <strong> Acomodação para deficiências - </strong> Em certos casos, podemos receber ou solicitar informações relacionadas à saúde, tais como o status de deficiência, a fim de fazer as acomodações necessárias durante nosso processo de entrevista.
                                        </li>
                                        <li>
                                          <strong> Prevenção de fraude - </strong> Podemos processar seus dados pessoais com o propósito de prevenção de fraude na busca dos legítimos interesses da empresa.
                                        </li>
                                        <li>
                                          <strong> Novas oportunidades de emprego - </strong> Podemos reter documentos relevantes coletados durante o processo de recrutamento contendo seus dados pessoais para futuras oportunidades relacionadas ao emprego
                                        </li>
                                      </ul>
                                      <p> No futuro, se pretendermos processar seus dados pessoais para uma finalidade diferente da mencionada acima, forneceremos as informações relevantes e obteremos seu consentimento quando for necessário fazê-lo. </p>
                                      <!-- third -->
                                      <h3>Security (Segurança)</h3>
                                      <p> We have implemented industry standard security measures to keep your personal data secure and confidential, including and not limited to: </p>
                                      <ul>
                                        <li> Limiting access to your personal data, to the persons as identified in this notice, strictly on a need to know basis, such as to respond to your inquiry or request. </li>
                                        <li> Implemented physical, electronic, administrative, technical and procedural safeguards that comply with all applicable laws and regulations to protect your personal data from unauthorized or inappropriate access, alteration, disclosure and destruction. It is important for you to protect against unauthorized access to your password and to your computer. Be sure to sign off when you are done using a shared computer. </li>
                                      </ul>
                                      <p> Implementamos medidas de segurança padrão do setor para manter seus dados pessoais seguros e confidenciais, incluindo, mas não se limitando a: </p>
                                      <ul>
                                        <li> Limitar o acesso a seus dados pessoais, às pessoas identificadas neste aviso, estritamente com base na necessidade de saber, como, por exemplo, para responder à sua consulta ou solicitação. </li>
                                        <li> Implementar salvaguardas físicas, eletrônicas, administrativas, técnicas e processuais que cumpram com todas as leis e regulamentos aplicáveis para proteger seus dados pessoais contra acesso não autorizado ou inadequado, alteração, divulgação e destruição. É importante que você se proteja contra acesso não autorizado a sua senha e a seu computador. Certifique-se de desconectar-se quando terminar de usar um computador compartilhado. </li>
                                      </ul>
                                      <!-- four -->
                                      <h3> Who we may share your personal data with (the recipients or categories of recipients of the personal data) (Com quem podemos compartilhar seus dados pessoais (os destinatários ou categorias de destinatários dos dados pessoais)) </h3>
                                      <ul>
                                        <li> We may use carefully selected third parties to carry out certain activities to help us to run our business (such as cloud service providers, IT support vendors), to facilitate language tests (language assessment agencies), to carry out background checks (background verification agencies) to facilitate your travel and expense (travel and immigration vendors) and to facilitate audits (third party auditors). </li>
                                        <li>
                                          <strong> We have offices and operations in a number of international locations and we share information between our branch offices and group companies for business and administrative purposes. Your information could be shared with our internal staff for screening and interview purposes. </strong>
                                        </li>
                                        <li> Where required for your role, your CV’s and business contact details may be shared with customers. </li>
                                        <li> Where required or permitted by law, information may be provided to others, such as regulators and law enforcement agencies. </li>
                                        <li> From time to time, we may consider corporate transactions such as a merger, acquisition, reorganisation, asset sale, or other similar transaction. In these instances, we may transfer or allow access to information to enable the assessment and undertaking of that transaction. If we buy or sell any business or assets, personal data may be transferred to a third party involved in the transaction. </li>
                                        <li> Podemos utilizar terceiros cuidadosamente selecionados para realizar determinadas atividades para nos ajudar a administrar nossos negócios (tais como provedores de serviços em nuvem, fornecedores de suporte de TI), para facilitar testes de idiomas (agências de avaliação de idiomas), para realizar verificações de histórico (agências de verificação de histórico) para facilitar suas viagens e despesas (fornecedores de viagens e imigração) e para facilitar auditorias (auditores terceirizados). </li>
                                        <li>
                                          <strong> Temos escritórios e operações em vários locais internacionais e compartilhamos informações entre nossas filiais e empresas do grupo para fins comerciais e administrativos. Suas informações podem ser compartilhadas com nossa equipe interna para fins de triagem e entrevista. </strong>
                                        </li>
                                        <li> Quando necessário para sua função, seu currículo e detalhes de contato podem ser compartilhados com os clientes. </li>
                                        <li> Quando exigido ou permitido por lei, as informações podem ser fornecidas a outros, tais como órgãos reguladores e de aplicação da lei. </li>
                                        <li> De tempos em tempos, podemos considerar transações corporativas, tais como fusão, aquisição, reorganização, venda de ativos ou outra transação semelhante. Nesses casos, podemos transferir ou permitir o acesso às informações para permitir a avaliação e a realização dessa transação. Se comprarmos ou vendermos qualquer negócio ou ativo, os dados pessoais poderão ser transferidos a terceiros envolvidos na transação. </li>
                                      </ul>
                                      <!-- five -->
                                      <h3> International and group company transfers of personal data (Transferências internacionais e de dados pessoais de empresas do grupo) </h3>
                                      <p> We are part of an international group of companies and, as such, transfer personal data concerning you to countries outside Brazil. Please see our website <a href="https://www.itcinfotech.com/" class="a-tag">www.itcinfotech.com</a> for details of the locations and our group companies. We transfer personal data between our group companies and data centres for the purposes described above. <strong>We may also transfer personal data to our third party suppliers outside of Brazil as described above.</strong>
                                      </p>
                                      <p> Your personal data may be stored in databases located outside of Brazil including in India. The database is controlled by our administrative staff located outside of Brazil including in India and can be accessed electronically. </p>
                                      <p> Where we transfer personal data outside of Brazil, for the purposes outlined in this document, ITC Infotech will take appropriate steps to ensure that transfers are in accordance with the applicable law and carefully managed to protect your privacy rights and interest. </p>
                                      <p> Fazemos parte de um grupo internacional de empresas e, como tal, transferimos dados pessoais a seu respeito para países fora do Brasil. Consulte nosso site <a href="https://www.itcinfotech.com/" class="a-tag">www.itcinfotech.com</a> para obter detalhes sobre os locais e as empresas do nosso grupo. </p>
                                      <p> Transferimos dados pessoais entre nossas empresas do grupo e centros de dados para os fins descritos acima. <strong>Também podemos transferir dados pessoais a nossos fornecedores de terceiros fora do Brasil, conforme descrito acima.</strong>
                                      </p>
                                      <p> Seus dados pessoais podem ser armazenados em bancos de dados localizados fora do Brasil, inclusive na Índia. O banco de dados é controlado por nossa equipe administrativa localizada fora do Brasil, incluindo a Índia, e pode ser acessado eletronicamente. </p>
                                      <p> Quando transferimos dados pessoais para fora do Brasil, para os fins descritos neste documento, a ITC Infotech tomará as medidas apropriadas para assegurar que as transferências estejam de acordo com a lei aplicável e cuidadosamente gerenciadas para proteger seus direitos e interesses de privacidade. </p>
                                      <!-- six -->
                                      <h3> Period for which the personal data will be stored (Período para o qual os dados pessoais serão armazenados) </h3>
                                      <p> We will retain your Personal Data and Sensitive Personal Data until their purpose is fulfilled or in case of legal or regulatory obligation. We retain your personal data for that period on our file to enable us to contact you in future about ongoing and potential employment opportunities at ITC Infotech. After this period, we will securely destroy your personal data in accordance with our data retention policy. </p>
                                      <p> You may choose to opt-out at any time by writing to us at <a href="mailto:Recruitment.Managers@ITCInfotech.com" class="a-tag">Recruitment.Managers@ITCInfotech.com</a>. Once you opt-out ITC Infotech will stop processing your personal data and delete it from our systems and hard copy documents. We will not be able to process your job application for any recruitment activities thereafter. </p>
                                      <p> For candidates whose application is successful, we shall retain the personal data in line with our Corporate Data Retention policies. </p>
                                      <p> Manteremos seus Dados Pessoais e Dados Pessoais Sensíveis até que sua finalidade seja cumprida ou em caso de obrigação legal ou regulamentar. Conservamos seus dados pessoais por esse período em nosso arquivo para que possamos contatá-lo no futuro sobre oportunidades de emprego em andamento e potenciais na ITC Infotech. Após esse período, destruiremos com segurança seus dados pessoais, de acordo com nossa política de retenção de dados. </p>
                                      <p> Você pode se descadastrar a qualquer momento, escrevendo-nos para Recruitment.Managers@ITCInfotech.com. Uma vez que você optar pelo descadastramento, a ITC Infotech deixará de processar seus dados pessoais e os excluirá de nossos sistemas e de nossos documentos impressos. Não poderemos processar sua solicitação de emprego para nenhuma atividade de recrutamento depois disso. </p>
                                      <p> Para os candidatos cuja candidatura for aprovada, reteremos os dados pessoais de acordo com nossas políticas corporativas de retenção de dados. </p>
                                      <h3> Existence of Automated Profiling and Decision Making (Existência de perfilagem e decisões automatizadas) </h3>
                                      <p> We use automated profiling, in limited circumstances as explained below: </p>
                                      <ul>
                                        <li> In certain instances, while hiring individuals for senior leadership roles, we utilise tools that aggregate publicly available data about the individual, analyses the text content, detects communication style, makes personality assumptions, and provides personality predictions, based on the person's writing style. This information shall be utilized by the recruitment team pursuant to our legitimate business interest in order to tailor our interview processes and communicate better with the candidate. </li>
                                        <li> While hiring individuals for entry level positions, we may utilise web-based tool to facilitate the interview & recruitment process. The tool maintains video records of the interview process. This insights of the profiling are utilised by the recruitment team pursuant to our legitimate business interest in order to help us take informed decisions while shortlisting our candidates. </li>
                                      </ul>
                                      <p> We do not carry out any processing activities that involve automated decisions. </p>
                                      <p> Utilizamos o perfil automatizado, em circunstâncias limitadas, conforme abaixo: </p>
                                      <ul>
                                        <li> Em certos casos, enquanto contratamos indivíduos para funções de liderança sênior, utilizamos ferramentas que agregam dados disponíveis publicamente sobre o indivíduo, analisam o conteúdo do texto, detecta o estilo de comunicação, faz suposições de personalidade e fornece previsões de personalidade, com base no estilo de escrita da pessoa. Estas informações devem ser utilizadas pela equipe de recrutamento de acordo com nosso legítimo interesse comercial, a fim de adequar nossos processos de entrevista e comunicar melhor com o candidato. </li>
                                        <li> Ao contratar indivíduos para posições de nível de ingresso, podemos utilizar uma ferramenta baseada na web para facilitar o processo de entrevista e recrutamento. A ferramenta mantém registros em vídeo do processo de entrevista. Estas percepções do perfil são utilizadas pela equipe de recrutamento de acordo com nosso legítimo interesse comercial, a fim de nos ajudar a tomar decisões informadas enquanto pré-selecionamos nossos candidatos. </li>
                                      </ul>
                                      <p> Não realizamos nenhuma atividade de processamento que envolva decisões automatizadas. </p>
                                      <!-- seven -->
                                      <h3> Your rights (Seus direitos) </h3>
                                      <p> You may have certain rights relating to your personal data under Article 18 of LGPD, such as the right, at no cost, to: </p>
                                      <ul>
                                        <li> Request confirmation on the existence of processing </li>
                                        <li> Request access to a copy of your personal data that ITC Infotech may hold and request details of the processing activities conducted by ITC Infotech. </li>
                                        <li> Request that your personal data is rectified if it is inaccurate or incomplete or outdated. </li>
                                        <li> Request deletion of personal data processed with your consent, except in certain circumstances as set out in Article 16 of LGPD. </li>
                                        <li> Request the anonymization, blocking or deletion of your personal data if it is no longer necessary or excessive of the purposes mentioned above or is processed in noncompliance with the provisions of the law restriction of the processing of your personal data by ITC Infotech in certain circumstances. </li>
                                        <li> Object to the processing of your personal data in certain circumstances. </li>
                                        <li> request information about the public and private entities with which ITC Infotech has shared your personal data. </li>
                                        <li> Lodge a complaint with the relevant supervisory authority. </li>
                                        <li> Object to, and not to be subject to a decision based solely on, automated processing (including profiling), which produces legal effects or significantly affects you. Where your personal data is being processed with your consent, withdraw your consent, without affecting the legality of the processing activities, based on your consent prior to such withdrawal. </li>
                                      </ul>
                                      <p> To exercise the rights outlined above in respect of your personal data you may submit a data subject request on our candidate portal the details of which has been sent to you via email on receiving your application. In case you face any issues in accessing our portal, you, or your legally appointed representatives, may also write to us at <a href="mailto:DPO@ITCInfotech.com" class="a-tag">DPO@ITCInfotech.com</a>. </p>
                                      <p> Você pode ter certos direitos relacionados aos seus dados pessoais nos termos do Artigo 18 da LGPD, tais como o direito, sem custo, de:</p>
                                      <ul>
                                        <li> Solicitar confirmação sobre a existência de processamento. </li>
                                        <li> Solicitar acesso a uma cópia de seus dados pessoais que a ITC Infotech poderá manter e solicitar detalhes das atividades de processamento conduzidas pela ITC Infotech. </li>
                                        <li> Solicitar que seus dados pessoais sejam retificados se estiverem imprecisos, incompletos ou desatualizados. </li>
                                        <li> Solicitar a exclusão dos dados pessoais processados com seu consentimento, exceto em determinadas circunstâncias, conforme estabelecido no artigo 16 da LGPD. </li>
                                        <li> Solicitar a anonimização, bloqueio ou exclusão de seus dados pessoais se estes não forem mais necessários ou excessivos das finalidades mencionadas acima ou se forem processados em desacordo com as disposições da lei de restrição do processamento de seus dados pessoais pelo ITC Infotech em determinadas circunstâncias. </li>
                                        <li> Opor-se ao processamento de seus dados pessoais em determinadas circunstâncias. </li>
                                        <li> Solicitar informações sobre as entidades públicas e privadas com as quais a ITC Infotech compartilhou e/ou compartilha seus dados pessoais. </li>
                                        <li> Apresentar uma reclamação à autoridade fiscalizadora competente. </li>
                                        <li> Proibir e não estar sujeito a uma decisão baseada unicamente no processamento automatizado (incluindo o perfil), que produza efeitos legais ou afete significativamente você. </li>
                                      </ul>
                                      <p> Para exercer os direitos descritos acima em relação aos seus dados pessoais, você pode enviar uma solicitação de dados em nosso portal de candidatos, cujos detalhes lhe foram enviados por e-mail ao receber sua solicitação. Caso você tenha algum problema ao acessar nosso portal, você, ou seus representantes legalmente nomeados, também podem nos escrever em <a href="mailto:DPO@ITCInfotech.com" class="a-tag"> DPO@ITCInfotech.com</a>
                                      </p>
                                      <!-- eight -->
                                      <h3> Contact (Contato) </h3>
                                      <p> If you have any questions or concerns about our privacy policy or your personal data that we process, please contact us <a href="mailto:Recruitment.Managers@ITCInfotech.com" class="a-tag"> Recruitment.Managers@ITCInfotech.com</a> or <a href="mailto:DPO@ITCInfotech.com" class="a-tag"> DPO@ITCInfotech.com</a>
                                      </p>
                                      <p> If you have any questions or concerns about the recruitment process, please contact us at <a href="mailto:Recruitment.Managers@ITCInfotech.com" class="a-tag"> Recruitment.Managers@ITCInfotech.com</a> or <a href="mailto:DPO@ITCInfotech.com" class="a-tag"> DPO@ITCInfotech.com</a>. </p>
                                      <p> Se você tiver alguma dúvida ou preocupação sobre nossa política de privacidade ou sobre os seus dados pessoais que processamos, entre em contato conosco via e-mail em: <a href="mailto:Recruitment.Managers@ITCInfotech.com" class="a-tag"> Recruitment.Managers@ITCInfotech.com</a> ou <a href="mailto:DPO@ITCInfotech.com" class="a-tag"> DPO@ITCInfotech.com</a>
                                      </p>
                                      <p> Se você tiver alguma dúvida ou preocupação sobre o processo de recrutamento, entre em contato conosco pelo e-mail <a href="mailto:Recruitment.Managers@ITCInfotech.com" class="a-tag"> Recruitment.Managers@ITCInfotech.com</a> ou <a href="mailto:DPO@ITCInfotech.com" class="a-tag"> DPO@ITCInfotech.com</a>. </p>
                                      <h3> Acknowledgement (Ciência): </h3>
                                      <p> I have read and am aware that my Personal Data and Sensitive Personal Data as per the above statements. </p>
                                      <p> Li e estou ciente de que meus Dados Pessoais e Dados Pessoais Sensíveis serão tratados em conformidade com as declarações deste aviso. </p>
                                    </div>
                                  </div>																						
                              </div> 
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!--brazil-->
<!--singapore-->
<div class="row custom-row-defthm">
  <div class="col-md-2 custom-labl-defthm">
  </div>
  <div class="col-md-8 custom-inpt-defthm" ng-init="policyCheckbox=false">
      <div id="readPolicy-5" class="modal fade" role="dialog">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <div class="modal-header">
                    <h4>Privacy Notice for Employment Candidates (Singapore)</h4>
                    <button type="button" class="close" type="button" data-bs-dismiss="modal">×</button>
                  </div>
                  <div class="modal-body">
                      <div class="policy">
                          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                              <div class="panel panel-default">
                                  <div class="policy-content">
                                    <div class="read-policy-div">
                                      <p> As part of its recruitment process, ITC Infotech India Limited, its subsidiaries and branches (hereinafter referred to as “ITC Infotech” or “we” or “our” or “us”) collects, uses, discloses and/or processes personal data relating to job applicants (hereinafter referred to as “you” or “your”). ITC Infotech is committed to being transparent about how it collects and uses personal data and to protect such personal data in accordance with data protection laws. </p>
                                      <p> The purpose of this notice is to make sure you are aware of what personal data we collect, how we use, disclose and take care of your personal data. This Privacy Notice describes our privacy policies and practices regarding the collection and use of your personal data, and sets out your privacy rights. </p>
                                      <!-- one -->
                                      <h3>1. Personal data we collect</h3>
                                      <p> We, at ITC Infotech, may collect certain personal data directly from you as well as from other available sources to the extent relevant and permitted by applicable local law. </p>
                                      <p> The categories of personal data that are typically collected and processed in the recruitment context are: </p>
                                      <ul>
                                        <li> Name, address, telephone number, email address, date of birth and other contact details; </li>
                                        <li> Competences, skills, experience and education, e.g. your CV or resume, previous employments, educational details and qualifications, third party references. </li>
                                        <li> Health such as disability status in order to make any necessary accommodations during our interview process. </li>
                                        <li> Languages spoken, Citizenship and Nationality. </li>
                                        <li> Preferences, e.g. preferred country of employment, areas of interest as well as your preferred ways to be contacted by ITC Infotech); </li>
                                        <li> Your online user identity, e.g. user names and other such information used in connection with authenticating you to our online recruitment management system. </li>
                                        <li> Technical Information: When you access our services online, our web servers automatically create records of your visit. Please read our website privacy policy by visiting <a href="https://www.itcinfotech.com/privacy-policy/" class="a-tag">https://www.itcinfotech.com/privacy-policy/</a> for more information. </li>
                                        <li> Personal data collected during interviews: As part of our interview process we may maintain audio/ video records of the interview (in case of telephone interviews or video enabled interviews) as well as comments noted by our interviewers. </li>
                                        <li> Other information, such as information found from public sources as well as information collected as part of reference checks, depending on the position you are applying for, and where necessary for the recruitment activities. </li>
                                        <li> Additionally, once you have been shortlisted, we may collect additional personal data such as Government ID, expectation of compensation and remuneration details, personal data relating to your family members (for benefits administration), passport, visa and work permit (in case of expatriate), background check reports including educational, employment and criminal checks etc. where permitted by local law. Also, in certain cases, we may collect your bank account details, details of the expense incurred and relevant bills to reimburse any expenses incurred by you during the interview processes. </li>
                                        <li> The personal data will be collected by us in a number of ways through multiple channels during the recruitment process and in relation to the employment opportunity with ITC Infotech: <ul>
                                            <li> Directly from you (When you apply online or via email or in person) </li>
                                            <li> Through referrals from your friends, family of professional connects who work at/ with ITC Infotech </li>
                                            <li> From third parties (through recruitment agencies an d background verification agencies), which may also include public sources such as professional networking platforms, social media platforms etc. </li>
                                          </ul>
                                        </li>
                                      </ul>
                                      <p> In the event that we have received your job application or personal data from any third party, you warrant that such third party has been duly authorised by you to disclose your personal data to us for the purposes set out in this notice. </p>
                                      <p> We do not collect any sensitive personal data (by which we mean either special category personal data or data relating to criminal convictions and offences) from you. In future if we need to collect any sensitive personal data for recruitment process, we will provide you with the relevant information and seek your consent for the processing. </p>
                                      <!-- two -->
                                      <h3>2. Purposes of processing your persona l data and the legal basis for processing</h3>
                                      <p> We, at ITC Infotech, are required to collect and process your personal data for the purposes of recruitment or resourcing activities. When you apply for a role with ITC Infotech you do so voluntarily and can at any time withdraw your application. Your personal data shall be utilized in the following ways: </p>
                                      <ul>
                                        <li>
                                          <strong>Communicating with you,</strong> in context of recruitment activities in accordance with the legitimate business interests of the company: <ul>
                                            <li> To obtain additional information about your suitability for the role and working location ; </li>
                                            <li> To inform you of available vacancies; </li>
                                            <li> To provide you with information relating to your application and to fulfil your requests. </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <strong> Managing recruitment and resourcing activities, </strong> including activities related to organizational planning. In the course of recruitment activities, we may use your personal data, in accordance with our legitimate business interests: <ul>
                                            <li> To set up and conduct interviews and assessments; </li>
                                            <li> To evaluate your application; </li>
                                            <li> To contact third party referees provided by you to evaluate your previous performances. </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <strong> In the event your profile is shortlisted, </strong> we may collect additional personal data (as mentioned in the previous section), to carry out background checks (in accordance with local law), facilitate travel for face to face interviews and to roll-out an employment offer letter.
                                        </li>
                                        <li>
                                          <strong> Monitoring and development of our recruitment process - </strong> We may use your personal data to develop and improve our recruitment processes, websites and other related services. This is in accordance with our legitimate 3 business interests to improve the services we provide. We may use aggregated anonymous information in the context of such development activities.
                                        </li>
                                        <li>
                                          <strong> Audit Compliance - </strong> We may process personal data as part of our audit processes and engage third party auditors, from time to time for our legitimate business interests and legal compliance.
                                        </li>
                                        <li>
                                          <strong> Accommodation for disabilities - </strong> In certain instances we may receive or request for information related to health such as disability status in order to make any necessary accommodations during our interview process.
                                        </li>
                                        <li>
                                          <strong> Prevention of fraud – </strong> We may process your personal data for the purpose of fraud prevention in pursuit of the legitimate interests of the company.
                                        </li>
                                        <li>
                                          <strong> New employment opportunities </strong> we may retain relevant documents collected during the recruitment process containing your personal data for future employment related opportunities.
                                        </li>
                                      </ul>
                                      <p> In the future, if we intend to process your personal data for a purpose other than that mentioned above, we will provide you with the relevant information and obtain your consent where it is necessary to do so. </p>
                                      <!-- three -->
                                      <h3> 3. Security </h3>
                                      <p> We have implemented industry standard security measures to keep your personal data secure and confidential, including and not limited to:</p>
                                      <ul>
                                        <li> Limiting access to your personal data, to the persons as identified in this notice, strictly on a need to know basis, such as to respond to your inquiry or request. </li>
                                        <li> Implemented physical, electronic, administrative, technica l and procedural safeguards that comply with all applicable laws and regulations to protect your personal data from unauthorized or inappropriate access, alteration, disclosure and destruction. </li>
                                      </ul>
                                      <!-- FOUR -->
                                      <h3> 4. Who we may share your personal data with (the recipients or categories of recipients of the personal data) </h3>
                                      <ul>
                                        <li> We may use carefully selected third parties to carry out certain activities to help us to run our business (such as cloud service providers, IT support vendors), to facilitate language tests (language assessm ent agencies), to carry out background checks (background verification agencies) to facilitate your travel and expense (travel and immigration vendors) and to facilitate audits (third party auditors). </li>
                                        <li> We have offices and operations in a number of international locations and we share information between our branch offices and group companies for business and administrative purposes. Your personal data could be shared with our internal staff for screening and interview purposes. </li>
                                        <li> Where required for your role, your CV’s and business contact details may be shared with customers. </li>
                                        <li> Where required or permitted by law, personal data may be provided to others, such as regulators and law enforcement agencies. </li>
                                        <li> From time to time, we may consider corporate transactions suc h as a merger, acquisition, reorganisation, asset sale, or other similar transaction. In these instances, we may transfer or allow access to information to enable the assessment and undertaking of that transaction. If we buy or sell any business or assets, personal data may be transferred to a third party involved in the transaction. </li>
                                      </ul>
                                      <!-- FIVE -->
                                      <h3> 5. International and group company transfers of personal data </h3>
                                      <p> We are part of an international group of companies and, as such, transfer personal data concerning you to countries outside Singapore. Please see our website <a href="https://www.itcinfotech.com/" class="a-tag" target="_blank">www.itcinfotech.com</a> for details of the locations and our group companies. </p>
                                      <p> We transfer personal data between our group companies and data centres for the purposes described above. We may also transfer personal data to our third party suppliers outside of Singapore as described above. </p>
                                      <p> Your personal data may be stored in databases located outside of Singapore including in India. The database is controlled by our administrative staff located outside of Singapore including in India and can be accessed electronically. </p>
                                      <p> Where we transfer personal data outside of Singapore, we will take appropriate steps to ensure that the overseas recipient is bound by legally enforceable obligations or specified certifications to provide the transferred personal data a standard of protection that is comparable to that under the PDPA. Such legally enforceable obligations will include contracts or binding corporate rules that impose a standard of protection that is comparable to that under the PDPA . </p>
                                      <p> If you would like more information on any of the data transfer mechanisms which we rely on, please contact our Data Protection Officer, details available in the contact section below. </p>
                                      <!-- six -->
                                      <h3> 6. Period for which the personal data will be stored </h3>
                                      <p> Your personal data will be retained as per our Data Retention and Disposal policy unless your application is under process for recruitment. We retain your personal data for that period on our file to enable us to contact you in future about ongoing and potential employment opportunities at ITC Infotech. After this period, we will securely destroy your personal data in accordance with our data retention policy. You may choose to opt-out at any time by writing to us at Recruitment.Managers@ITCInfotech.com. Once you opt-out ITC Infotech will stop processing your personal data and delete it from our systems and hard copy documents. We will not be able to process your job application for any recruitment activities thereafter. </p>
                                      <p> For candidates whose application is successful, we shall retain the personal data in line with our Corporate Data Retention policies. </p>
                                      <!-- seven -->
                                      <h3> 7. Your rights </h3>
                                      <p> To the extent applicable under data protection laws in your jurisdiction, you have a right to:</p>
                                      <ul>
                                        <li> Request access to your personal data and request details of the processing activities conducted by ITC Infotech. </li>
                                        <li> Request that your personal data is rectified if it is inaccurate or incomplete. </li>
                                        <li> Request erasure of your personal data in certain circumstances. </li>
                                        <li> Request restriction of the processing of your personal data by ITC Infotech in certain circumstances. </li>
                                        <li> Object to the processing of your personal data in certain circumstances. </li>
                                        <li> Receive your personal data in a structured, commonly used and machine - readable format in certain circumstances. </li>
                                        <li> Object to, and not to be subject to a decision based solely on, automated processing (including profiling), which produces legal effects or significantly affects you. </li>
                                        <li> Withdraw any consent you have provided to us at any time by contacting us. </li>
                                      </ul>
                                      <p> To exercise the rights outlined above in respect of your personal data you may submit a data subject request on our candidate portal the details of which has been sent to you via email on receiving your application. In case you face any issues in accessing our portal, you may also write to us at <a href="mailto:DPO@ITCInfotech.com" class="a-tag">DPO@ITCInfotech.com</a>. </p>
                                      <!-- eight -->
                                      <h3> 8. Contact </h3>
                                      <p> If you have any questions or concerns about our privacy policy or your personal data that we process, or about the recruitment process, please contact us <a href="mailto:Recruitment.Managers@ITCInfotech.com" class="a-tag">Recruitment.Managers@ITCInfotech.com</a>. </p>
                                    </div>
                                  </div>																						
                              </div> 
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!--singapore-->