import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-joblist',
  templateUrl: './joblist.component.html',
  styleUrls: ['./joblist.component.scss']
})
export class JoblistComponent {
  fieldValue: any = "";
  allJobsCount: any;
  jobRoute: string = '';
  @ViewChild('filtersPopup') filtersPopup: any;
  popUpRef: any;
  isMobileView: boolean = false;
  clearAll: boolean = false;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private appService: AppService, private el: ElementRef, private dialog: MatDialog) { 
    this.isMobileView = window.innerWidth < 992;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isMobileView = window.innerWidth < 992;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      console.log(params);
      this.fieldValue = params.fieldValue;
    });
  }

  openPopup() {
    this.popUpRef = this.dialog.open(this.filtersPopup, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      maxHeight: '100%'
    });
  }
  closePopup() {
    this.popUpRef.close('true');
  }
  clearSideFilter() {
    this.clearAll = true;
    setTimeout(() => {
      this.clearAll = false;
    }, 0);
  }

  refreshJobsOnSearch(e: any) {
    console.log("Search button hit");

  }

  showRecommendedJobs: boolean = false;
  onRecommendeJobsFetch(e: any) {
    this.showRecommendedJobs = true;
    console.log("recommended jobs from app", e);

  }

  resetRecommendedJobsEventHandler(e: any) {
    this.showRecommendedJobs = !e;
  }

  setJobURL(jobURL: any): void {
    let url = window.location.href;
    url = url.substring(0, url.indexOf('jobslist/')) + 'jobview/' + jobURL;
    // let url = this.router.serializeUrl(this.router.createUrlTree(['jobview/' + jobURL]));
    window.open(url, '_blank');
  }

  setJobsCount(jobCount: any) {
    this.allJobsCount = jobCount;
  }

  setTalentApplyResult(event: any) {
    if (event.code == 200) {
      this.appService.toast('success', event.message);
    }
    else {
      this.appService.toast('error', event.message);
    }
  }
}

