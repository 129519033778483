import { Component } from '@angular/core';

@Component({
  selector: 'app-why-itc-infotech',
  templateUrl: './why-itc-infotech.component.html',
  styleUrls: ['./why-itc-infotech.component.scss']
})
export class WhyItcInfotechComponent {

}
